import { TripSummary } from "@shared/types/summary";
import './HomeItem.scss';
import ImageCarousel, { ImageInfo } from "../../widget/ImageCarousel";
import { Link } from "react-router-dom";
import React from "react";
import { getImagePlace } from "trip-util";

interface HomeItemProps {
  trip: TripSummary;
}

export function getImages(trip: TripSummary): ImageInfo[] {
  return trip.images.map(image => ({
    url: `/image?place=${getImagePlace(image.place, image.location)}`,
    tooltip: image.place
  }));
}

function HomeItem0(props: HomeItemProps) {
  const { trip } = props;

  return (
    <div className='home-item-container'>
      <div className='item-image-container'>
        <Link to={`/outline/${trip._id}`}>
          <ImageCarousel images={getImages(trip)} />
        </Link>
      </div>
      <div className='title'>
        <Link to={`/outline/${trip._id}`}>
          {trip.title}
        </Link>
      </div>
      <div className='description'>
        {trip.description}
      </div>
      <div className='link-div'>
        <Link to={`/outline/${trip._id}`}>
          Outline
        </Link>
        <Link to={`/open/${trip._id}`}>
          Explore & Plan
        </Link>
      </div>
    </div >
  );
}

export const HomeItem = React.memo(HomeItem0);