import { useEffect, useMemo, useRef, useState } from 'react';
import './Outline.scss';
import { AppToolbar } from '../component/AppToolbar';
import { TripOutline } from "@shared/types/chat";
import { getTripOutline } from "../service/chatRequests";
import { OutlineItem } from "../component/trips/OutlineItem";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Backdrop, CircularProgress, Divider, Tooltip } from '@mui/material';
import { popReturnUrl, pushReturnUrl } from '../util/ui-util';

export function Outline(props: {}) {
  const [tripOutline, setTripOutline] = useState<TripOutline>();
  const { _id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {returnUrl, returnState} = useMemo(() => popReturnUrl(location), []);
  const linkState = pushReturnUrl(location);

  useEffect(() => {
    getTripOutline(_id!).then(outline => {
      if(typeof outline === 'string') {
        alert(outline);
        navigate('/featured-trips');
      }

      setTripOutline(outline);
    }).catch(ex => {
      alert(ex);
      navigate('/featured-trips');
    });
  }, []);

  function back() {
    navigate(returnUrl || '/featured-trips', { state: returnState });
  }

  return (
    <div className='outline-top-container'>
      <AppToolbar current='outline' />
      <Divider />
      <div className='outline-title-bar'>
        <div className='outline-title'>{tripOutline?.tripName}</div>
        <div className='link-div'>
          <Tooltip title='Edit Itinerary'>
            <Link to={`/open/${_id}`} state={linkState}>
              <img src="/images/chatgpt-icon.svg" width={20} height={20} />
              Explore & Plan
            </Link>
          </Tooltip>
          <div className='link-div' onClick={back}>
            Back
          </div>
        </div>
      </div>
      <div className='outline-content-container'>
        {tripOutline?.days?.map((d, idx) => <OutlineItem day={d} key={idx} />)}
      </div>
      <Backdrop open={!tripOutline} sx={{ zIndex: 10000 }}>
        <CircularProgress sx={{ color: 'white' }} />
      </Backdrop>
    </div>
  );
}
