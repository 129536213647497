import { Plan, PlanEvent } from "../../../../shared/types/plan";
import { Attraction, Trip } from "../../../../shared/types/itinerary";
import { Event, dateFnsLocalizer } from 'react-big-calendar';
import { format, getDay, getDayOfYear, parse, startOfWeek } from "date-fns";
import { enUS } from "date-fns/locale/en-US";
import { getLocationCity, getLocationsString } from "trip-util";
import _ from "lodash";

export interface BigCalendarEvent extends Event, PlanEvent {
  title: string;
  dayIndex: number;
  eventIndex: number;
  tooltip?: string;
  dayTrip?: string;
}

export function getPlanEvent(event: BigCalendarEvent): PlanEvent {
  return {
    title: event.title,
    fromItinerary: event.fromItinerary,
    notes: event.notes,
    timeOfDay: event.timeOfDay,
    eventType: event.eventType,
    foodType: event.foodType,
    arriving: event.arriving,
    duration: event.duration
  };
}

export function dateDiff(nstart?: Date, ostart: Date = new Date()): number {
  return !nstart ? NaN : Math.floor((nstart.getTime() - ostart.getTime()) / (1000 * 60 * 60 * 24));
}

// this is used to limit the days shown in the calendar to the days in plan.
export function getRangeLocalizer(start: Date | undefined, plan: Plan | undefined) {
  const locales = {
    'en-US': enUS,
  };
  const localizer0 = dateFnsLocalizer({
    format, parse, startOfWeek: () => startOfWeek(new Date()), getDay, locales,
  });

  const ovisibleDays = localizer0.visibleDays;
  localizer0.visibleDays = (date: Date, localizer: any) => {
    const dates = ovisibleDays(date, localizer);

    if(start && plan?.days?.length) {
      const weeks: Date[][] = [];
      const startDayOfYear = getDayOfYear(start);
      let days = plan?.days?.length;

      for(let i = 0; i < dates.length && days >= 0; i += 7) {
        const weekStartDayOfYear = getDayOfYear(dates[i]);
        const weekEndDayOfYear = getDayOfYear(dates[i + 6]);
        const fromBeginningOfWeek = startDayOfYear - weekStartDayOfYear;

        // check for both begin and end of week for case where the first week
        // spans across year boundaries.
        if(fromBeginningOfWeek < 7 && startDayOfYear <= weekEndDayOfYear) {
          days -= 7 - (weeks.length === 0 ? fromBeginningOfWeek : 0);
          weeks.push(dates.slice(i, i + 7));
        }

        // include previous week if start is at beginning of a week
        if(fromBeginningOfWeek === 7) {
          weeks.push(dates.slice(i, i + 7));
        }
      }

      if(weeks.length > 0) {
        return weeks.flat();
      }
    }

    return dates;
  };
  return localizer0;
}

export function findAttraction(event: BigCalendarEvent, plan?: Plan, trip?: Trip): Attraction | undefined {
  if(!plan?.days || !trip?.itinerary) {
    return undefined;
  }

  const location = plan.days[event.dayIndex]?.title;

  for(let i of trip?.itinerary) {
    if(i.overnightCity === location) {
      for(let a of i.attractions) {
        if(a.place === event.title) {
          return a;
        }
      }
    }
  }
}

export function getTravelPlan(event: BigCalendarEvent, location?: string): string {
  function getTravelTimeLabel(event: BigCalendarEvent): string {
    if(event.timeOfDay instanceof Date) {
      let hourMinute = event.timeOfDay.getHours() + event.timeOfDay.getMinutes() / 60;

      if(event.arriving) {
        hourMinute += event.duration ?? 0;
      }

      const getTwo = (n: number) => {
        const s = `${Math.floor(n)}`;
        return `00${s}`.substring(s.length);
      }

      return `${getTwo(hourMinute)}:${getTwo(hourMinute * 60 % 60)}`;
    }

    return '';
  }

  const travelPlan = event.eventType?.startsWith('travel-')
    ? `${event.arriving ? 'Arriving' : 'Departing'} ${location} at ${getTravelTimeLabel(event)}`
    : '';
  return travelPlan && event.notes ? `${travelPlan}\n${event.notes}`
    : (travelPlan || event.notes || '');
}

export function getDayCellLabel(eventTitle?: string, dayTrip?: string): string {
  const title = getLocationCity(eventTitle) || '';
  const locations = dayTrip?.split('/').map(d => getLocationCity(d)).join('/');
  return dayTrip && dayTrip !== eventTitle ? `${locations}\n(${title})` : title;
}

export function deleteEvent(event: BigCalendarEvent, plan: Plan) {
  // find event to delete. BigCalendarEvent contains other fields not in PlanEvent so we only compare the
  // fields in PlanEvent
  const idx = plan.days[event.dayIndex].events.findIndex(e => _.isEqual(event, { ...event, ...e }));

  if(idx >= 0) {
    plan.days[event.dayIndex].events.splice(idx, 1);
  }

  // remove day if event is empty and day is at ends of range (only user added days)
  if(plan.days[event.dayIndex].events.length === 0) {
    if(event.dayIndex === 0 && plan.itineraryOffset > 0) {
      plan.days.splice(0, 1);
      plan.start && plan?.start.setDate(plan?.start.getDate() + 1);
      plan.itineraryOffset = Math.max(0, plan.itineraryOffset - 1);
    }
    else if(event.dayIndex === plan.days.length - 1 &&
      plan.days.length > plan.itineraryOffset + plan.itineraryLength) {
      plan.days.splice(event.dayIndex, 1);
    }
  }
}

export function orderEvent(event: BigCalendarEvent, plan: Plan, up: boolean) {
  const events = plan.days[event.dayIndex].events;

  if(up) {
    const removed = events.splice(event.eventIndex - 1, 1);
    events.splice(event.eventIndex, 0, ...removed);
  }
  else {
    const removed = events.splice(event.eventIndex, 1);
    events.splice(event.eventIndex + 1, 0, ...removed);
  }

  let lunch = events.findIndex(e => e.eventType === 'food' && e.timeOfDay === 'afternoon');
  let dinner = events.findIndex(e => e.eventType === 'food' && e.timeOfDay === 'evening');
  events.forEach((e, i) => {
    if(e.eventType === 'activity') {
      if(lunch && i < lunch) {
        e.timeOfDay = 'morning';
      }
      else if(i > dinner) {
        e.timeOfDay = 'evening';
      }
      else if(i !== lunch && i !== dinner) {
        e.timeOfDay = 'afternoon';
      }
    }
  });
}

// strip airbnb or similar service names from the string.
export function stripAirbnbs(title: string): string {
  const airbnbs: string[] = [
    "airbnb",
    "vrbo",
    "booking.com",
    "tripadvisor rentals",
    "expedia",
    "couchsurfing",
    "homestay",
    "housetrip",
    "agoda homes",
    "9flats",
    "onefinestay",
    "holidu",
    "interhome",
    "plum guide",
    "novasol",
    "turnkey",
    "vacasa",
    "sonder",
    "getaway",
    "casai",
    "vivastay",
    "despegar",
    "zizaike",
    "tujia",
    "rakuten lifull stay",
    "stayz",
    "oyo rooms",
    "flat4day",
    "jumia travel",
    "sleepout",
    "trustedhousesitters",
    "hipcamp",
    "outdoorsy",
    "the plum guide",
    "flipkey"
  ];

  title = title.toLowerCase();

  for(let service of airbnbs) {
    title = title.replaceAll(service, ' ');
  }

  return title;
}

export function getGoogleQ(event: BigCalendarEvent, location: string) {
  if(event.eventType === 'activity' || event.eventType === 'food') {
    return encodeURIComponent(`${event.title},${location}`);
  }
  else if(event.eventType === 'hotel') {
    return encodeURIComponent(`${stripAirbnbs(event.title)},${location},${event.notes}`);
  }

  return '';
}

export function searchEvent(event: BigCalendarEvent, location: string) {
  window.open(`https://www.google.com/search?q=${getGoogleQ(event, location)}`, '_blank');
}

export function mapEvent(event: BigCalendarEvent, location: string) {
  window.open(`https://www.google.com/maps?q=${getGoogleQ(event, location)}`, '_blank');
}