import { Button, ButtonGroup, FormControlLabel, Switch, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { Trip } from "../../../shared/types/itinerary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faSailboat } from "@fortawesome/free-solid-svg-icons";
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import UndoIcon from '@mui/icons-material/Undo';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ShareIcon from '@mui/icons-material/Share';
import { ViewMode, ViewType } from "../page/TripChat";
import "./TopToolbar.scss";
import { useEffect, useRef, useState } from "react";
import { Plan } from "../../../shared/types/plan";
import axios from "axios";
import { saveAs } from 'file-saver';
import SaveDialog from "./dialog/SaveDialog";
import ShareDialog from "./dialog/ShareDialog";
import { TripAccess } from "@shared/types/UserTrip";
import BroadcastMessage from "../widget/BroadcastMessage";
import CustomTooltip from "../widget/CustomTooltip";

interface TopToolbarProps {
  _id?: string;
  trip: Trip;
  plan?: Plan;
  view: ViewType;
  newTrip: boolean;
  readonly: boolean;
  publicAccess: TripAccess;
  changeView: (event: any, view: ViewType) => void;
  viewMode: ViewMode;
  setViewMode: (viewMode: ViewMode) => void;
  currentName: string;
  saveTrip: (trip: string, saveAs: boolean) => void;
  close: () => void;
  onSetStart?: () => void;
  onRefresh?: () => void;
  onUndo?: () => void;
  onShare?: (publicAccess: TripAccess) => void;
}

export function TopToolbar(props: TopToolbarProps) {
  const { _id, trip, plan, view, newTrip, readonly, publicAccess, changeView, viewMode, setViewMode,
    currentName, saveTrip, close, onSetStart, onRefresh, onUndo, onShare } = props;
  const [openInputPrompt, setOpenInputPrompt] = useState<boolean>(false);
  const [openShare, setOpenShare] = useState<boolean>(false);
  const [overviewTip, setOverviewTip] = useState<boolean>(false);
  const tipShown = useRef(false);

  useEffect(() => {
    if(trip && !tipShown.current) {
      tipShown.current = true;
      setOverviewTip(true);
      setTimeout(() => setOverviewTip(false), 5000);
    }
  }, [trip]);

  // calendar only has detail and overview
  function getViewMode(): ViewMode {
    return view === 'plan' && viewMode === 'compact' ? 'detail' : viewMode ?? 'detail';
  }

  async function download() {
    try {
      const response = await axios.post('/export',
        { plan: plan },
        {
          responseType: 'blob',
          withCredentials: true
        }
      );

      const blob = new Blob([response.data]);
      saveAs(blob, "itinerary.xlsx");
    }
    catch(error) {
      alert(`Error downloading the Excel file: ${error}`);
    }
  }

  const viewModeTip = `Overview: Drag and drop to order days\nCompact: Drag and drop to order attractions`;

  return <div>
    <div className='top-command-container'>
      <div className="left-bar">
        <ToggleButtonGroup exclusive size="small" value={view} onChange={changeView}>
          <Tooltip title="Tell me where you want to go and what are your interests, and we will create your itinerary together.">
            <ToggleButton value="explore" aria-label="bold" sx={{ gap: 1 }} >
              <FontAwesomeIcon icon={faSailboat} size='xl' className="nav-icon" />
              <span className="nav-button">
                Explore
              </span>
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Plan your trip on a calendar, and add your own attractions or change your itinerary as you wish.">
            <span>
              <ToggleButton value="plan" aria-label="bold" sx={{ gap: 1 }} disabled={!trip?.itinerary?.length}>
                <FontAwesomeIcon icon={faCalendarDays} size='xl' className="nav-icon" />
                <span className="nav-button">
                  Plan
                </span>
              </ToggleButton>
            </span>
          </Tooltip>
        </ToggleButtonGroup>
        <CustomTooltip open={overviewTip} title={viewModeTip} placement="right" arrow
          onOpen={() => setOverviewTip(true)} onClose={() => setOverviewTip(false)}>
          <ToggleButtonGroup exclusive size="small" value={getViewMode()} onChange={(event, mode) => setViewMode(mode)}>
            <Tooltip title="Detailed Itinerary">
              <ToggleButton value="detail" aria-label="bold" sx={{ gap: 1 }} >
                <span className="nav-button nav-button2">
                  Detail
                </span>
              </ToggleButton>
            </Tooltip>
            {view === 'explore' ?
              <Tooltip title="Itinerary with attractions.">
                <ToggleButton value="compact" aria-label="bold" sx={{ gap: 1 }} >
                  <span className="nav-button nav-button2">
                    Compact
                  </span>
                </ToggleButton>
              </Tooltip> : null
            }
            <Tooltip title="Day ovewview.">
              <ToggleButton value="dayOverview" aria-label="bold" sx={{ gap: 1 }} >
                <span className="nav-button nav-button2">
                  Overview
                </span>
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
        </CustomTooltip>
      </div>
      <ButtonGroup variant='outlined'>
        {view === "plan" && onSetStart ?
          <Tooltip title="Set Start Date">
            <Button onClick={() => onSetStart()}>
              <AirplanemodeActiveIcon fontSize="small" />
            </Button>
          </Tooltip> : null
        }
        {view === "plan" ?
          <Tooltip title="Refresh from Itinerary">
            <span>
              <Button onClick={() => onRefresh && onRefresh()} disabled={!onRefresh}>
                <RefreshIcon fontSize="small" />
              </Button>
            </span>
          </Tooltip> : null
        }
        <Tooltip title="Undo">
          <span>
            <Button onClick={() => onUndo && onUndo()} disabled={!onUndo}>
              <UndoIcon fontSize="small" />
            </Button>
          </span>
        </Tooltip>
        <Tooltip title="Download">
          <span>
            <Button onClick={download} disabled={!plan}>
              <DownloadIcon fontSize="small" />
            </Button>
          </span>
        </Tooltip>
        <Tooltip title="Share">
          <span>
            <Button disabled={!trip?.itinerary?.length} onClick={() => setOpenShare(true)}>
              <ShareIcon fontSize="small" />
            </Button>
          </span>
        </Tooltip>
        <Tooltip title="Save Itinerary">
          <span>
            <Button disabled={!trip?.itinerary?.length || !currentName} onClick={() => saveTrip(currentName, false)}>
              <SaveIcon fontSize="small" />
            </Button>
          </span>
        </Tooltip>
        <Tooltip title="Save As">
          <span>
            <Button disabled={!trip?.itinerary?.length} onClick={(event) => setOpenInputPrompt(true)}>
              <SaveAsIcon fontSize="small" />
            </Button>
          </span>
        </Tooltip>
        <Tooltip title="Close">
          <Button onClick={close}>
            <CloseIcon fontSize="small" />
          </Button>
        </Tooltip>
      </ButtonGroup>
      <SaveDialog open={openInputPrompt} inputValue={currentName} newTrip={newTrip} readonly={readonly}
        onSubmit={saveTrip} onClose={() => setOpenInputPrompt(false)} />
      <ShareDialog tripId={_id} publicAccess={publicAccess} open={openShare}
        onShare={onShare} onClose={() => setOpenShare(false)} />
    </div>
    <BroadcastMessage />
  </div>;
}