import React, { useEffect, useState } from 'react';
import { Paper, SxProps } from "@mui/material";
import { Trip, TripDay } from "../../../../shared/types/itinerary";
import { TripDayComponent } from './TripDayComponent';
import './TripComponent.scss';
import {
  DragDropContext, Droppable, Draggable, DropResult, DroppableProps, DroppableProvided, DraggableProvided
} from 'react-beautiful-dnd';
import { ViewMode } from '../../page/TripChat';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if(!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};

interface TripComponentProps {
  trip: Trip;
  style: SxProps;
  viewMode?: ViewMode;
  selectedDays: Set<number>;
  onSelect: (days: Set<number>) => void;
  onChange: (trip: Trip, instruction?: string) => void;
  onAdd: (day: TripDay) => void;
  onDelete: (day: TripDay) => void;
  onReplaceAttraction: (day: TripDay, index: number) => void;
}

interface DraggableDay extends TripDay {
  key: string;
}

function TripComponent0(props: TripComponentProps) {
  const { trip, style, viewMode, onSelect, onChange, onAdd, onDelete, onReplaceAttraction } = props;
  const [selectedDays, setSelectedDays] = useState<Set<number>>(props.selectedDays);
  const [days, setDays] = useState<DraggableDay[]>([]);
  let attractionNumber = 0;

  useEffect(() => {
    setDays(trip?.itinerary?.map(i => ({ ...i, key: `day${i.day}` })) || []);
  }, [trip]);

  useEffect(() => {
    setSelectedDays(props.selectedDays);
  }, [props.selectedDays]);

  function clicked(event: React.MouseEvent, index: number) {
    let days = new Set(selectedDays);

    if(days.has(index)) {
      if(event.altKey) {
        days.clear();
      }
      else {
        days.delete(index);
      }
    }
    else if(trip.itinerary) {
      if(event.altKey) {
        days = new Set(Array(trip.itinerary.length).keys())
      }
      else if(event.ctrlKey) {
        days.add(index);
      }
      else {
        days = new Set([index]);
      }
    }

    setSelectedDays(days);
    onSelect(days);
  }

  function deleteDay(index: number) {
    days.splice(index, 1);
    setDaysAndTrip(days);
    onDelete(trip.itinerary![index]);

    if(selectedDays.has(index)) {
      selectedDays.delete(index);
      setSelectedDays(selectedDays);
    }
  }

  function changeDayTrip(index: number, day: TripDay, prevStay: string) {
    for(let i = index; i < days.length && days[i].overnightCity === day.overnightCity; i++) {
      days[i] = {
        ...days[i],
        overnightCity: prevStay
      };
    }
    setDaysAndTrip(days);
  }

  // change this day as the base for subsequent cities.
  function changeBase(index: number, day: TripDay) {
    const prevStay = day.overnightCity;

    for(let i = index; i < days.length && days[i].overnightCity === prevStay; i++) {
      days[i] = {
        ...days[i],
        overnightCity: day.locations[day.locations.length - 1].location
      };
    }
    setDaysAndTrip(days);
  }

  function changeDay(index: number, day: TripDay, instruction?: string) {
    days[index] = { ...day, key: `` };
    setDaysAndTrip(days, instruction);
  }

  const onDragEnd = (result: DropResult) => {
    if(!result.destination || !days) return;

    const newItems = Array.from(days);
    const sourceIdx = result.source.index;
    const toIdx = result.destination.index;
    let n = 1;

    // the source is the first day of a city, move all days in the same city together.
    if(sourceIdx === 0 || days[sourceIdx].overnightCity !== days[sourceIdx - 1].overnightCity) {
      for(let i = sourceIdx + 1; i < days.length; i++) {
        if(days[i].overnightCity === days[sourceIdx].overnightCity) {
          n++;
        }
        else {
          break;
        }
      }
    }

    const reorderedItems = newItems.splice(sourceIdx, n);
    newItems.splice(toIdx, 0, ...reorderedItems);

    // day trip, change overnight to previous day
    if(reorderedItems[0]?.locations[0].location !== reorderedItems[0]?.overnightCity) {
      const overnightCity = toIdx > 0 ? newItems[toIdx - 1].overnightCity : reorderedItems[0]?.locations[0].location;
      reorderedItems.forEach(d => d.overnightCity = overnightCity);
    }

    setDaysAndTrip(newItems);
  }

  function onMoveAttraction(fromDay: number, fromAttraction: number, toDay: number, toAttraction: number) {
    const ndays = days.map(d => ({
      ...d,
      attractions: d.attractions.map(a => ({
        ...a
      }))
    }));
    const [attraction] = ndays[fromDay].attractions.splice(fromAttraction, 1);
    const adj = fromDay === toDay && fromAttraction < toAttraction ? -1 : 0;
    ndays[toDay].attractions.splice(toAttraction + adj, 0, attraction);
    setDaysAndTrip(ndays);
  }

  function setDaysAndTrip(newItems: DraggableDay[], instruction?: string) {
    setDays(newItems);
    onChange({
      ...trip,
      days: newItems.length,
      itinerary: newItems.map((i, index) => {
        const { key, ...day } = i;
        return { ...day, day: index + 1 };
      })
    }, instruction);
  };

  return (
    <Paper sx={{ ...style }} className='trip-container'>
      <DragDropContext onDragEnd={onDragEnd}>
        {viewMode === 'dayOverview' ?
          <StrictModeDroppable droppableId='droppable'>
            {getDayList}
          </StrictModeDroppable> :
          getDayList()
        }
      </DragDropContext>
    </Paper>
  );

  function getDayList(provided?: DroppableProvided): any {
    attractionNumber = 0;

    return (
      <DndProvider backend={HTML5Backend}>
        <div {...provided?.droppableProps} ref={provided?.innerRef} className='list'>
          {days.map((day, index) => (
            viewMode === 'dayOverview' ?
              <Draggable key={day.key} draggableId={day.key} index={index}>
                {(provided) => getDayItem(day, index, provided)}
              </Draggable> :
              getDayItem(day, index)
          ))}
          {provided?.placeholder}
        </div>
      </DndProvider>
    );
  }

  function getDayItem(day: DraggableDay, index: number, provided?: DraggableProvided) {
    const selected = selectedDays.has(index);
    const div = (
      <div ref={provided?.innerRef} className='list-item' key={index}
        {...provided?.draggableProps}
        {...provided?.dragHandleProps}>
        <TripDayComponent day={day} selected={selected} viewMode={viewMode}
          attractionNumber={selected ? attractionNumber : undefined}
          onSelect={(event) => clicked(event, index)}
          onDelete={() => deleteDay(index)}
          onAdd={onAdd}
          onDayTrip={(day, prevStay) => changeDayTrip(index, day, prevStay)}
          onBase={(day) => changeBase(index, day)}
          onChange={(day, instruction) => changeDay(index, day, instruction)}
          onReplaceAttraction={(day, index) => onReplaceAttraction(day, index)}
          onMoveAttraction={onMoveAttraction}
          prevStay={index > 0 ? trip?.itinerary?.[index - 1]?.overnightCity : undefined} />
      </div>
    );

    if(selected) {
      attractionNumber += day.attractions.length;
    }

    return div;
  }
}

export const TripComponent = React.memo(TripComponent0);
