import { TripSummary } from '@shared/types/summary';
import './FeaturedTripItem.scss';
import ImageCarousel from '../../widget/ImageCarousel';
import { getImages } from './HomeItem';
import { Link, useLocation } from 'react-router-dom';
import React, { useMemo } from 'react';
import { pushReturnUrl } from '../../util/ui-util';

interface FeaturedTripItemProps {
  trip: TripSummary;
}

function FeaturedTripItem0(props: FeaturedTripItemProps) {
  const { trip } = props;
  const location = useLocation();
  const linkState = pushReturnUrl(location);

  return (
    <div className='summary-item-container'>
      <div className='summary-images-container'>
        <Link to={`/outline/${trip._id}`} state={linkState}>
          <ImageCarousel images={getImages(trip)} />
        </Link>
      </div>
      <div className='summary-content'>
        <div className='title-div'>
          <div className='title'>
            <Link to={`/outline/${trip._id}`} state={linkState}>
              <div className='title1'>
                {trip.title}
              </div>
              <div className='title2'>
                {trip.tripName}
              </div>
            </Link>
          </div>
        </div>
        <div className='item-description'>
          {trip.description}
        </div>
        <div className='link-div'>
          <Link to={`/outline/${trip._id}`} state={linkState}>
            Outline
          </Link>
          <Link to={`/open/${trip._id}`} state={linkState}>
            Explore & Plan
          </Link>
        </div>
      </div>
    </div>
  );
}

export const FeaturedTripItem = React.memo(FeaturedTripItem0);