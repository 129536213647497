import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Paper, Tooltip } from "@mui/material";
import { ChatRecord } from '../../page/TripChat';
import ReactMarkDown from 'react-markdown';
import ExpandIcon from '@mui/icons-material/Expand';
import CompressIcon from '@mui/icons-material/Compress';
import "./ChatHistory.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';

interface ChartHistoryProps {
  records: ChatRecord[];
}

export function ChatHistory(props: ChartHistoryProps) {
  const { records } = props;
  const [expanded, setExpanded] = useState<boolean>(false);
  const lastAnswerLines = useRef<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const divRefs = useRef<(HTMLDivElement | null)[]>([]);

  scrollToTopOfText();

  useEffect(() => {
    scrollToTopOfText();
  }, [records]);

  function computeMargin(div: HTMLDivElement): number {
    const ps: HTMLCollection = div.children;
    let h = 0;

    for(let i = 0; i < ps.length; i++) {
      const style = window.getComputedStyle(ps[i]);
      // only add the top margin since the bottom margin will be collapsed into the next div
      h += parseFloat(style.marginTop);
    }

    return h;
  }

  function scrollToTopOfText() {
    const container = containerRef.current;

    if(divRefs.current.length > 1 && container) {
      const divs = divRefs.current;
      // ignore the last two text so the scrollTop is at the last question.
      const ignore = lastAnswerLines.current + 1;
      const txtHeight = Array.from(divs).slice(0, -ignore)
        .map(d => {
          return d ? (d.offsetHeight + computeMargin(d)) : 0;
        })
        .reduce((a, b) => a + b, 0);
      container.scrollTop = txtHeight;
    }
  }

  function getChartTexts(): JSX.Element[] {
    let lines: JSX.Element[] = [];
    let i = 0;

    for(let record of records) {
      const elems = getChatText(record, i);
      lines = lines.concat(elems);
      i += elems.length;
      lastAnswerLines.current = elems.length;
    }

    return lines;
  }

  function getChatText(record: ChatRecord, i: number): JSX.Element[] {
    const ai = record.textType === 'ai';
    const className = record.textType === 'user' ? 'user-text' : ai ? 'ai-text' : '';
    return record.text.split('\n').map((line, k) =>
      <div key={`${i + k}`} ref={(el) => divRefs.current[i + k] = el} className={`question-answer ${className}`}>
        {ai ? <FontAwesomeIcon icon={faRobot} /> : null}
        <ReactMarkDown>{line}</ReactMarkDown>
      </div >
    );
  }

  function toggleExpand() {
    setExpanded(!expanded);
  }

  return <Paper className={`history-container ${expanded ? 'expanded' : ''}`} ref={containerRef}>
    {getChartTexts()}
    <Tooltip title={expanded ? 'Shrink' : 'Expand'}>
      <IconButton className='expand-icon' onClick={toggleExpand}>
        {expanded ? <CompressIcon /> : <ExpandIcon />}
      </IconButton>
    </Tooltip>
  </Paper>;
}
