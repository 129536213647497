import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './widget/ErrorFallback';
import { tripCache, TripCacheContext } from './service/TripCache';
import { UserContext, userManager } from './service/UserContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function resetError() {
  window.location.href = '/';
}

// <React.StrictMode></React.StrictMode>
root.render(
  <ErrorBoundary FallbackComponent={ErrorFallback} onReset={resetError}>
    <UserContext.Provider value={userManager}>
      <TripCacheContext.Provider value={tripCache}>
        <App />
      </TripCacheContext.Provider>
    </UserContext.Provider>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
