import React from 'react';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';

export type SortingOption = 'newest' | 'oldest' | 'shortest' | 'longest';

interface SortingDropdownProps {
  selectedOption: SortingOption;
  onChange: (option: SortingOption) => void;
}

const SortingDropdown: React.FC<SortingDropdownProps> = ({
  selectedOption,
  onChange,
}) => {
  const sortingOptions: { label: string; value: SortingOption }[] = [
    { label: 'Newest', value: 'newest' },
    { label: 'Oldest', value: 'oldest' },
    { label: 'Shortest', value: 'shortest' },
    { label: 'Longest', value: 'longest' },
  ];

  return (
    <FormControl variant="outlined">
      <InputLabel id="sorting-dropdown-label">Sort By</InputLabel>
      <Select sx={{ height: '40px', minWidth: '120px' }}
        labelId="sorting-dropdown-label"
        value={selectedOption}
        onChange={(e) => onChange(e.target.value as SortingOption)}
        label="Sort By"
      >
        {sortingOptions.map((option) => (
          <MenuItem key={option.value} value={option.value} >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SortingDropdown;
