import React from 'react';
import { Container, Typography, Box, Link, Divider } from '@mui/material';
import { AppToolbar } from '../component/AppToolbar';
import './AboutPage.scss';

const AboutPage: React.FC = () => {
  return (
    <div className='about-page-container'>
      <AppToolbar current='admin' />
      <div className='about-page-content'>
        <Box
          component="img"
          src="/images/joyful-traveler-posing.jpg"
          alt="Service Description"
          sx={{ width: '100%', height: '100%' }}
        />
        <div className='about-page-right-content'>
          <h3 className='title1'>
            About Map<div className='n-and'>N</div>Hat Itinerary Builder
          </h3>
          <Divider/>
          <Typography variant="body1" paragraph>
            <p>
              Welcome to Map<span className='n-and'>N</span>Hat! If you're anything like me—a travel enthusiast
              at heart—you know the thrill of exploring new places but also the *not-so-thrilling* side of planning.
              Crafting the perfect itinerary usually means hours of research, plotting routes on maps,
              juggling timelines, hunting down transportation, and finally organizing everything on paper.
              I used to end up with a cluttered, hand-filled calendar that needed constant updates.
            </p>
            <p>
              Then I tried ChatGPT, which gave me a great starting point, but the itineraries were long and hard
              to edit or memorize. So, I created Map<span className='n-and'>N</span>Hat—a tool designed to
              make itinerary planning a breeze.  With Map<span className='n-and'>N</span>Hat, you get a streamlined,
              interactive experience that takes the hassle out of organizing your travel plans. I hope it makes
              your travel adventures as fun to plan as they are to experience!
            </p>
          </Typography>

          <Divider/>
          <h3 className='title1'>
            Contact Us
          </h3>
          <Typography variant="body1" textAlign={'center'}>
            Questions/Feedbacks: <Link href="mailto:info@mapnhat.com">info@mapnhat.com</Link>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
