import React, { KeyboardEvent, useEffect, useState } from 'react';
import { TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, Typography } from '@mui/material';

interface InputPromptDialogProps {
  open: boolean;
  label: string;
  placeholder?: string;
  inputValue?: string;
  inputType?: string;
  prefix?: string; // prefix [ input ] postfix
  postfix?: string;
  onClose?: () => void;
  onSubmit: (value: string) => void;
};

export function InputPromptDialog(props: InputPromptDialogProps) {
  const { open, label, placeholder, inputType, prefix, postfix, onClose, onSubmit } = props;
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    setInputValue(props.inputValue ?? '')
  }, [props.inputValue]);

  const handleChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    onSubmit(inputValue);
    onClose && onClose();
  };

  const handleClose = () => {
    onClose && onClose();
  };

  function keyDown(event: KeyboardEvent) {
    if(event.key === 'Enter') {
      handleSubmit();
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{label}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }}>
          <Typography>{prefix}</Typography>
          <TextField sx={{ width: `${inputType === 'number' ? '80px' : '50vw' }`, maxWidth: '500px' }} autoFocus
            placeholder={placeholder}
            value={inputValue}
            onKeyDown={keyDown}
            onChange={handleChange}
            inputProps={{ type: inputType || 'text' }}
            variant='outlined'
          />
          <Typography>{postfix}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button variant='contained' onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InputPromptDialog;
