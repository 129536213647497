import { useEffect, useState } from 'react';
import {
  TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import "./DayDialog.scss";
import { TripDay } from '@shared/types/itinerary';

interface EventDialogProps {
  open?: boolean;
  day: TripDay;
  onClose?: () => void;
  onSubmit?: (notes: string) => void;
};

export function DayDialog(props: EventDialogProps) {
  const { open, day, onClose, onSubmit } = props;
  const [notes, setNotes] = useState<string>('');

  useEffect(() => {
    setNotes(day?.notes ?? '');
  }, [day]);

  const handleSubmit = () => {
    onSubmit && onSubmit(notes);
    onClose && onClose();
  };

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Dialog open={!!open} onClose={handleClose} className='day-dialog'>
      <DialogTitle>Edit Notes</DialogTitle>
      <DialogContent className='day-dialog-container'>
        <TextField margin="dense" label="Notes" multiline={true} fullWidth className='notes-field'
          rows={8} value={notes} onChange={event => setNotes(event.target.value)} />
      </DialogContent>
      <DialogActions className='command-container'>
        <Button variant='contained' onClick={handleClose} color='secondary'>Cancel</Button>
        <Button variant='contained' onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DayDialog;
