import { Trip, TripDay } from '@shared/types/itinerary';

// get the city part of the location
export function getLocationCity(location?: string): string | undefined {
  return location?.split(',')?.[0];
}

// get the locations of all attractions in the day, as a string.
export function getLocationsString(tripDay: TripDay, full = false): string {
  return tripDay.locations.map(loc => full ? loc.location : getLocationCity(loc.location)).join('/').trim();
}

// get the day title
export function getTripDayTitle(day: TripDay): string {
  const locations = getLocationsString(day);
  const overnight = getLocationCity(day.overnightCity);

  if(locations === overnight || !overnight) {
    return locations;
  }

  return `${locations} (${overnight})`;
}

// get the locations visited in the day, including the previous overnight.
export function getTransportationLocations(trip: Trip, i: number): string[] {
  const alocs = trip?.itinerary?.[i].attractions
    ?.map(a => a.location) || [];

  return [ trip.itinerary![i - 1].overnightCity, alocs, trip.itinerary![i].overnightCity ]
    .flat()
    .filter((a, idx, self) => self.indexOf(a) === idx);
}

// get the image place paramter to be used for /image?place=... url paramter.
export function getImagePlace(place: string, location?: string): string {
  return encodeURIComponent(location ? `${place},${location}` : place);
}
