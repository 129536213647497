import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Container, Button, Divider, Tooltip, Snackbar, Checkbox, CircularProgress, IconButton } from "@mui/material";
import InventoryIcon from "@mui/icons-material/Inventory";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import axios from 'axios';
import { TripInfo } from '../../../shared/types/tripInfo';
import { TripItem } from '../component/trips/MyTripItem';
import "./MyTrips.scss";
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ConfirmDialog from '../widget/ConfirmDialog';
import { useTripCache } from '../service/TripCache';
import { AppToolbar } from '../component/AppToolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarOutline } from '@fortawesome/free-regular-svg-icons';
import { useUserContext } from '../service/UserContext';
import { SearchField } from '../component/trips/SearchField';
import SortingDropdown, { SortingOption } from '../component/trips/SortingDropdown';
import { containsWordBoundary, getLocalStorageValue, pushReturnUrl } from '../util/ui-util';

const MyTrips: React.FC = () => {
  const [trips, setTrips] = useState<TripInfo[]>();
  const [searchParams] = useSearchParams();
  const getSearchStr = () => searchParams.get('search') || '';
  const [archivedTrips, setArchivedTrips] = useState<TripInfo[]>();
  const [unsavedTrip, setUnsavedTrip] = useState<TripInfo>();
  const [showArchive, setShowArchive] = useState<boolean>(localStorage.getItem('tripx.showArchive') === 'true');
  const [showFeatured, setShowFeatured] = useState<boolean>(localStorage.getItem('tripx.showFeatured') === 'true');
  const [message, setMessage] = useState<string>('');
  const [searchStr, setSearchStr] = useState<string>(() => getSearchStr());
  const [searching, setSearching] = useState<boolean>(() => !!getSearchStr());
  const [sortingOption, setSortingOption] = useState<SortingOption>(() =>
    searchParams.get('sort') as SortingOption || 'newest');
  const navigate = useNavigate();
  const location = useLocation();
  const linkState = pushReturnUrl(location);
  const tripService = useTripCache();
  const userManager = useUserContext();
  const first = useRef<boolean>(true);
  const tripDeleted = (_id: string) => {
    tripService.deleteTrip(_id, showArchive, showArchive ? setArchivedTrips : setTrips)
      .then(ack => {
        setMessage(ack ? "Itinerary successfully deleted" : "Failed to delete itinerary");
      }).catch(ex => setMessage(`Failed to delete trip: ${ex}`));
  };

  const archiveChanged = (_id: string, changeMode?: boolean): Promise<any> => {
    return tripService.flipArchive(_id, showArchive, setTrips, setArchivedTrips)
      .then(ack => {
        if(showArchive) {
          setMessage(ack ? "Itinerary successfully unarchived." : "Failed to unarchive itinerary");

          if(changeMode) {
            setShowArchive(!showArchive);
          }
        }
        else {
          setMessage(ack ? "Itinerary successfully archived." : "Failed to archive itinerary");
        }
      }).catch(ex => setMessage(`Failed to delete trip: ${ex}`));
  };

  const setFeatured = (_id: string, featured: boolean) => {
    const ntrips = trips?.map(t => ({
      ...t,
      featured: t._id === _id ? featured : t.featured
    }));
    setTrips(ntrips);

    // refresh to make sure the client is in sync with server.
    const pending = tripService.setFeatured(_id, featured)
      .then(a => tripService.refreshTrips().then(t => {
        tripService.setPendingChange(undefined);
        setTrips(t);
      }));
    tripService.setPendingChange(pending);
  };

  useEffect(() => {
    tripService.getTrips().then(t => setTrips(t));
    tripService.getArchivedTrips().then(t => setArchivedTrips(t));
    tripService.getUnsavedTrip().then(t => setUnsavedTrip(t || undefined));
    first.current = false;
  }, [tripService.getUpdateTime()]);

  useEffect(() => {
    if(showArchive && archivedTrips?.length == 0) {
      setShowArchive(false);
    }
  }, [archivedTrips]);

  useEffect(() => {
    const search = searchStr ? `search=${searchStr}` : '';
    const params = search ? `?${search}&sort=${sortingOption}` : `?sort=${sortingOption}`;
    navigate(`/mytrips${params}`, { replace: true });
  }, [searchStr, sortingOption]);

  function handleUnsaved(button: string) {
    if(button === 'Yes') {
      navigate(`/open/${unsavedTrip?._id}?unsaved=true`, { state: linkState })
    }
    else {
      axios.delete(`/unsaved-trip`).catch(ex => setMessage("Error: " + ex));
    }

    setUnsavedTrip(undefined);
    tripService.setUnsavedTrip(null);
  }

  function getTrips0(): TripInfo[] {
    return (showArchive ? archivedTrips
      : (showFeatured ? trips : trips?.filter(t => !t.featured))) || [];
  }

  function getTrips(): TripInfo[] {
    const arr = getTrips0();
    return sortTrip(searching && searchStr ? arr?.filter(a => matchTrip(searchStr, a)) : arr, sortingOption);
  }

  function sortTrip(items: TripInfo[], option: SortingOption): TripInfo[] {
    return items.sort((a, b) => {
      switch(option) {
        case 'newest':
          return new Date(b.modified).getTime() - new Date(a.modified).getTime();
        case 'oldest':
          return new Date(a.modified).getTime() - new Date(b.modified).getTime();
        case 'shortest':
          return a.days - b.days;
        case 'longest':
          return b.days - a.days;
        default:
          throw new Error(`Invalid sort option: ${option}`);
      }
    });
  }

  function matchTrip(searchStr: string, trip: TripInfo) {
    if(!searchStr) {
      return true;
    }

    return containsWordBoundary(`${trip.tripName} ${trip.summary}`, searchStr, false);
  }

  function showArchiveChanged(value: boolean) {
    setShowArchive(value);
    localStorage.setItem('tripx.showArchive', value + '');
  }

  function showFeaturedChanged(value: boolean) {
    setShowFeatured(value);
    localStorage.setItem('tripx.showFeatured', value + '');
  }

  function clearSearch() {
    setSearchStr('');
    setSearching(false);
  }

  return (
    <div>
      <AppToolbar current='myTrips' />
      <Container maxWidth={false} className='home-container'>
        <div className='inner-container'>
          <div className='border-container'>
            <div className='button-container'>
              <SortingDropdown selectedOption={sortingOption} onChange={option => setSortingOption(option)} />
              {searching ?
                <SearchField clearSearch={clearSearch} searchStr={searchStr} setSearchStr={setSearchStr}
                  className='search-field' /> :
                <Link to="/create" state={linkState} className='start-button'>
                  <Button variant='contained' className='start-button'>Start a New Trip</Button>
                </Link>
              }
              <Tooltip title='Search'>
                <IconButton size='small' onClick={() => setSearching(!searching)}>
                  <FontAwesomeIcon icon={faSearch} />
                </IconButton>
              </Tooltip>
              {!showArchive && userManager.getUser()?.admin ?
                <Tooltip title={showFeatured ? 'Hide Featured Itineraries' : 'Show Featured Itineraries'}>
                  <IconButton size='small' onClick={() => showFeaturedChanged(!showFeatured)}>
                    <FontAwesomeIcon icon={showFeatured ? faStar : faStarOutline} />
                  </IconButton>
                </Tooltip> : null
              }
              {archivedTrips && archivedTrips?.length > 0 ?
                <Tooltip title={`${showArchive ? 'Show My Trips' : 'Show Archived Trips'}`}>
                  <Checkbox checked={showArchive} onChange={() => showArchiveChanged(!showArchive)}
                    icon={<EditCalendarIcon />} checkedIcon={<InventoryIcon />} />
                </Tooltip> : null
              }
            </div>
            <Divider />
            <div className='trip-container'>
              {getTrips()?.map((trip, i) =>
                <TripItem trip={trip} archived={showArchive} onDelete={tripDeleted} onArchive={archiveChanged}
                  onFeatured={setFeatured}
                  key={i} />
              )}
            </div>
            {!trips ? <div className='loading'><CircularProgress /></div> : null}
          </div>
          <a className="freepik-link"
            href="https://www.freepik.com/free-photo/female-tourists-hand-have-happy-travel-map_3953407.htm">
            Image by jcomp on Freepik</a>
        </div>
        <ConfirmDialog open={!!unsavedTrip && first.current} buttons={['No', 'Yes']} onSubmit={handleUnsaved}
          label={`Continue to edit the previous trip (${unsavedTrip?.tripName})?`} />
        <Snackbar open={!!message} message={message} autoHideDuration={10000}
          onClose={() => setMessage("")}></Snackbar>
      </Container>
    </div>
  );
};

export default MyTrips;

