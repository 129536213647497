import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Container, Stack, TextField, IconButton, Grid, CircularProgress, Backdrop, Chip, Snackbar
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import { Conversation } from "../../../shared/types/chat";
import { Trip, TripDay } from "../../../shared/types/itinerary";
import { TripComponent } from '../component/chat/TripComponent';
import { ChatHistory } from '../component/chat/ChatHistory';
import "./TripChat.scss"
import { ask, save } from '../service/chatRequests';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { TripMap } from '../component/map/TripMap';
import { TripCalendar } from '../component/calendar/TripCalendar';
import { TopToolbar } from '../component/TopToobar';
import { Plan } from '../../../shared/types/plan';
import { createPlanFromTrip } from "../component/calendar/planConvertion";
import ConfirmDialog from '../widget/ConfirmDialog';
import _ from 'lodash';
import { TripAccess } from '@shared/types/UserTrip';
import { useTripCache } from '../service/TripCache';
import { TripData } from '@shared/types/tripInfo';
import { updateTransportationInfo } from '../component/chat/tripUtil';
import InputPromptDialog from '../widget/InputPromptDialog';
import { popReturnUrl } from '../util/ui-util';

export interface ChatRecord {
  text: string;
  textType: "system" | "user" | "ai";
}

export type ViewType = 'explore' | 'plan';

export type ViewMode = 'detail' | 'compact' | 'dayOverview';

interface TripChatProps {
}

function TripChat(props: TripChatProps) {
  const location = useLocation();
  const { returnUrl, returnState } = useMemo(() => popReturnUrl(location), []);
  const ticket = new URLSearchParams(location.search).get('ticket');
  const unsaved0 = new URLSearchParams(location.search).get('unsaved') === 'true';
  const { _id } = useParams();
  const [unsaved, setUnsaved] = useState<boolean>(unsaved0);
  const [tripName, setTripName] = useState<string>('');
  const [currId, setCurrId] = useState<string | undefined>();
  const [records, setRecords] = useState<ChatRecord[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [trip, setTrip] = useState<Trip>({});
  const [plan, setPlan] = useState<Plan | undefined>();
  const [history, setHistory] = useState<Conversation[]>([]);
  const [selectedDays, setSelectedDays] = useState<Set<number>>(new Set());
  const [loading, setLoading] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<ViewMode>('detail');
  const [message, setMessage] = useState<string>("");
  const [currentName, setCurrentName] = useState<string>("");
  const [defaultName, setDefaultName] = useState<boolean>(false);
  const [view, setView] = useState<ViewType>('explore');
  const [tripHistory, setTripHistory] = useState<Trip[]>([]);
  const [planHistory, setPlanHistory] = useState<Plan[]>([]);
  const [dirty, setDirty] = useState<boolean>(false);
  const [confirmType, setConfirmType] = useState<'unsaved' | 'plan-change' | 'idle-chat' | ''>('');
  const [dayToAdd, setDayToAdd] = useState<TripDay>();
  const [readonly, setReadonly] = useState<boolean>(false);
  const [publicAccess, setPublicAccess] = useState<TripAccess>();
  const [idleChat, setIdleChat] = useState<number>(0);
  const navigate = useNavigate();
  const tripService = useTripCache();
  const question_create: ChatRecord = {
    textType: "ai",
    text: "Tell me where you want to go and for how many days, e.g. 7 days in south france."
  };
  const question_modify: ChatRecord = {
    textType: "ai",
    text: "Do you want to change the itinerary?"
  };

  useEffect(() => {
    if(_id) {
      setLoading(true);
      axios.get<TripData>(`/trip/${_id}?ticket=${ticket || ''}&unsaved=${unsaved}`).then(result => {
        const tripData: TripData = result.data;

        if(!tripData.trip) {
          alert("You have no permission to access the itinerary.");
          navigate('/');
          return;
        }

        setDefaultName(tripName === getDefaultName(tripData.trip));

        const plan: Plan = tripData.plan!;
        const trip: Trip = tripData.trip!;

        setLoading(false);
        setTripName(tripData.tripName ?? '');
        setReadonly(!!tripData.readonly);
        setPublicAccess(tripData.publicAccess);
        setTrip(trip);
        setPlan(plan);

        setRecords([trip?.days ? question_modify : question_create]);
        setTripHistory(tripHistory.concat([structuredClone(trip)]));
        setPlanHistory(planHistory.concat([structuredClone(plan)]));

        // if already started planning stage, should continue in plan.
        if(plan?.start) {
          setView('plan');
        }
      }).catch(ex => {
        setLoading(false);
        setMessage(ex + "");
      });
    }
    else {
      setRecords([question_create]);
    }
  }, []);

  useEffect(() => {
    setCurrentName(tripName && !defaultName ? tripName : getDefaultName(trip));

    if(!unsaved) {
      saveUnsavedTrip();
    }
  }, [trip, tripName])

  useEffect(() => {
    if(currId) {
      navigate(`/open/${currId}`, { replace: true });
    }
  }, [currId]);

  useEffect(() => {
    const handleBeforeUnload = (event: { preventDefault: () => void; returnValue: string; }) => {
      if(dirty) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dirty]);

  useEffect(() => {
    setSelectedDays(new Set());
  }, [viewMode]);

  const getDefaultName = (trip: Trip) => trip ? `${trip.days} Days in ${trip.destinations}` : '';

  async function submit() {
    const question: string = inputRef?.current?.value!;
    submit0(question);
  }

  async function submit0(question: string) {
    setLoading(true);
    ask(question, history, trip)
      .then(response => {
        setLoading(false);
        setDirty(true);

        if(response.trip && !_.isEqual(response.trip, trip)) {
          setTrip(response.trip);
          setTripHistory(tripHistory.concat([structuredClone(response.trip)]));
          setIdleChat(0);
        }
        else {
          const idle = idleChat + 1;

          if(idle >= 10) {
            setConfirmType('idle-chat');
            setIdleChat(0);
          }
          else {
            setIdleChat(idle);
          }
        }

        records.push({ text: question, textType: "user" });
        response.message && records.push({ text: response.message, textType: "ai" });
        setRecords(records.concat([]));
        setHistory(history.concat([{
          question: question,
          answer: response.message
        }]).slice(-100));
        inputRef.current && (inputRef.current.value = "");
      })
      .catch(error => {
        setLoading(false);
        setMessage(error.message || error + '');
      });
  }

  function keyDown(event: React.KeyboardEvent) {
    if(event.key === 'Enter') {
      submit();
    }
  }

  function saveTrip(name: string, saveAs: boolean) {
    setDefaultName(name === getDefaultName(trip));
    // clear flag so if a user tries to close, won't get a warning.
    setTimeout(() => setDirty(false), 1000);
    tripService.setUnsavedTrip(null);
    const pendingSave = save(name, trip, plan, saveAs ? undefined : (currId || _id))
      .then(result => {
        setDirty(false);
        setMessage(result.message ? result.message + '' : '');
        setReadonly(false);
        tripService.setPendingChange(undefined);
        tripService.refreshTrips();

        axios.delete(`/unsaved-trip`)
          .then(ack => setUnsaved(false))
          .catch(ex => console.log("Failed to delete trip: ", ex));

        if(result.insertedId) {
          setCurrId(result.insertedId);
        }
      })
      .catch(error => {
        console.log("Error:", error);
        tripService.setPendingChange(undefined);
        setMessage(error.message || error + '');
      });
    tripService.setPendingChange(pendingSave);
  }

  function saveUnsavedTrip() {
    if(!trip?.days) {
      return;
    }

    save(tripName || getDefaultName(trip), trip, plan, currId || _id, true)
      .then(result => { })
      .catch(error => setMessage(`Failed to save trip: ${error}`));
  }

  function getConfirmMessage(): string {
    switch(confirmType) {
      case 'unsaved':
        return 'You have unsaved changes, discard changes?'
      case 'plan-change':
        return 'Plan has been modified. Refreshing will cause your changes to be lost. Proceed?';
      case 'idle-chat':
        return `Seems you have finished exploring. Switch to 'Plan' to make detailed plan?`;
    }

    return "Proceed?";
  }

  function close() {
    if(dirty) {
      setConfirmType('unsaved');
    }
    else {
      closeConfirmed();
    }
  }

  function closeConfirmed(button: string = "Yes") {
    if(button === "Yes") {
      if(confirmType === 'plan-change') {
        refreshPlan(true);
      }
      else if(confirmType === 'idle-chat') {
        setView('plan');
      }
      else {
        axios.delete(`/unsaved-trip`).then(ack => { })
          .catch(ex => setMessage(`Failed to delete trip: ${ex}`));
        navigate(returnUrl || '/mytrips', { state: returnState });
      }
    }

    setConfirmType('');
  }

  function changeView(event: any, view: ViewType) {
    if(view) {
      setView(view);
    }
  }

  function tripChanged(trip: Trip, instruction?: string) {
    if(instruction) {
      records.push({ text: instruction, textType: "user" });
    }

    setDirty(true);
    setIdleChat(0);
    setTrip(trip);
    setTripHistory(tripHistory.concat([structuredClone(trip)]));
    saveUnsavedTrip();
    updateTransportationInfo(trip)
      .then(trip => trip && setTrip(trip))
      .catch(ex => setMessage('Error: ' + ex));
  }

  function addDay(day: TripDay) {
    setDayToAdd(day);
  }

  function addDay0(days: string) {
    setDayToAdd(undefined);
    setTripHistory(tripHistory.concat([structuredClone(trip)]));
    submit0(`Add ${days} extra day${parseInt(days) > 1 ? 's' : ''} for ${dayToAdd?.overnightCity} to itinerary ` +
      `after day ${dayToAdd?.day}`);
  }

  function onSelectDay(day: TripDay) {
    const days = trip.itinerary?.filter(d => d.locations[0].location === day.locations[0].location).map(d => d.day - 1);
    setSelectedDays(new Set(days));
  }

  function deleteDay(day: TripDay) {
    records.push({ text: `Remove day ${day.day} from the itinerary`, textType: "user" });
    records.push({ text: `Day ${day.day}, ${day.overnightCity} removed from the itinerary`, textType: "ai" });
  }

  function replaceAttraction(day: TripDay, index: number) {
    setTripHistory(tripHistory.concat([structuredClone(trip)]));
    submit0(`Replace ${day.attractions[index].place} with another attraction.`)
  }

  function planChanged(plan: Plan) {
    setDirty(true);
    setPlan(plan);
    setPlanHistory(planHistory.concat([structuredClone(plan)]));
    saveUnsavedTrip();
  }

  function undo() {
    if(view === "plan") {
      if(planHistory.length > 1) {
        planHistory.pop();
        setPlan(planHistory[planHistory.length - 1]);
      }
    }
    else {
      if(tripHistory.length > 1) {
        tripHistory.pop();
        setTrip(tripHistory[tripHistory.length - 1]);
        history.pop();
        setHistory(history);
      }
    }
  }

  function setStart() {
    setPlan({
      itineraryOffset: plan?.itineraryOffset || 0,
      itineraryLength: plan?.itineraryLength || 0,
      days: plan?.days || [],
      start: undefined
    });
  }

  function refreshPlan(confirmed: boolean = false) {
    const nplan = createPlanFromTrip(trip, plan?.start, plan);

    if(!_.isEqual(plan, nplan) && !confirmed) {
      setConfirmType('plan-change');
    }
    else {
      planChanged(nplan);
    }
  }

  return (
    <Container className="trip-container" maxWidth={false} sx={{ margin: "10px" }}>
      <Backdrop open={loading} sx={{ zIndex: 10000 }}>
        <CircularProgress sx={{ color: 'white' }} />
      </Backdrop>
      <Stack spacing={1} sx={{ height: 'calc(100vh - 30px)' }}>
        <TopToolbar _id={_id} trip={trip} plan={plan} close={close} newTrip={!_id && !currId} readonly={readonly}
          publicAccess={publicAccess} view={view} changeView={changeView}
          viewMode={viewMode} setViewMode={setViewMode}
          currentName={currentName} saveTrip={saveTrip} onSetStart={setStart}
          onRefresh={plan?.start ? refreshPlan : undefined}
          onUndo={(view === 'plan' ? planHistory.length : tripHistory.length) > 1 ? undo : undefined}
          onShare={(publicAccess) => setPublicAccess(publicAccess)}
        />
        {view !== 'explore' ?
          <TripCalendar style={{ flex: 2, height: 'calc(100vh - 70px)' }} trip={trip}
            plan={plan} onChange={planChanged} onTripChange={tripChanged} overview={viewMode === 'dayOverview'} /> :
          <div className='explore-container'>
            <Grid container className='middle-container'>
              <div className='topDiv'>
                <TripComponent style={{ flex: 2, maxHeight: '100%', overflow: 'auto' }} trip={trip}
                  viewMode={viewMode} selectedDays={selectedDays} onSelect={(days) => setSelectedDays(days)}
                  onChange={tripChanged} onAdd={addDay} onDelete={deleteDay} onReplaceAttraction={replaceAttraction}
                />
                <TripMap trip={trip} selectedDays={selectedDays}
                  onSelect={onSelectDay} onClear={() => setSelectedDays(new Set())} />
                <Chip className='pexels-ack' variant='filled' label='Photos provided by Pexels'></Chip>
              </div>
              <ChatHistory records={records} />
            </Grid>
            <TextField label="Question" variant='filled' inputRef={inputRef} sx={{ height: '1rem' }}
              onKeyDown={keyDown} autoFocus
              InputProps={{
                endAdornment: (
                  <IconButton type="submit" onClick={submit}>
                    <SendIcon />
                  </IconButton>
                )
              }}
            />
          </div>}
      </Stack>
      <Snackbar open={!!message} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setMessage("")} message={message} />
      <ConfirmDialog open={!!confirmType} label={getConfirmMessage()}
        buttons={['No', 'Yes']} onSubmit={closeConfirmed} />
      <InputPromptDialog open={!!dayToAdd} label={`Add Days to Itinerary?`}
        prefix='Add' postfix={`day(s) to ${dayToAdd?.overnightCity} to the itinerary?`}
        inputType='number' inputValue='1' onSubmit={addDay0} onClose={() => setDayToAdd(undefined)} />
    </Container >
  );
}

export default TripChat;