import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './page/Login';
import Profile from './page/Profile';
import ProtectedRoute from './widget/ProtectedRoute';
import TripChat from './page/TripChat';
import axios, { AxiosError } from 'axios';
import ConfirmDialog from './widget/ConfirmDialog';
import { convertDates } from 'trip-util';
import { FeaturedTrips } from './page/FeaturedTrips';
import MyTrips from './page/MyTrips';
import { Home } from './page/Home';
import "./global.scss";
import { Outline } from './page/Outline';
import { useUserContext } from './service/UserContext';
import { AdminPage } from './page/AdminPage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AboutPage from './page/AboutPage';

const App: React.FC = () => {
  const [error, setError] = useState<string>("");
  const userManager = useUserContext();

  axios.defaults.withCredentials = true;
  axios.interceptors.response.use(
    (response) => {
      response.data = convertDates(response.data);
      return response;
    },
    (error: AxiosError) => {
      if(error.response?.status === 401 && !(error.request as XMLHttpRequest)?.responseURL.endsWith("/checkAuth")) {
        console.log("failed 401:", error);
        setError("Session expired, re-login...");
      }

      return Promise.reject(error);
    }
  );
  userManager.init();

  const theme = createTheme({
    palette: {
      primary: {
        main: '#5e84c5',
      },
      secondary: {
        main: '#8991a2',
      },
    },
 });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/login" Component={Login} />
          <Route path="/mytrips" element={<ProtectedRoute><MyTrips /></ProtectedRoute>} />
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/featured-trips" element={<FeaturedTrips region='all'/>} />
          <Route path="/featured-trips/asia" element={<FeaturedTrips region='asia'/>} />
          <Route path="/featured-trips/europe" element={<FeaturedTrips region='europe'/>} />
          <Route path="/featured-trips/north-america" element={<FeaturedTrips region='north america'/>} />
          <Route path="/featured-trips/central-south-america" element={<FeaturedTrips region='central & south america'/>} />
          <Route path="/featured-trips/oceania" element={<FeaturedTrips region='oceania'/>} />
          <Route path="/featured-trips/others" element={<FeaturedTrips region='others'/>} />
          <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route path="/create" element={<ProtectedRoute><TripChat /></ProtectedRoute>} />
          <Route path="/open/:_id" element={<ProtectedRoute><TripChat /></ProtectedRoute>} />
          <Route path="/outline/:_id" element={<Outline />} />
          <Route path="/admin" element={<ProtectedRoute><AdminPage /></ProtectedRoute>} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="*" element={<Navigate to='/' />} />
        </Routes>
        <ConfirmDialog open={!!error} label={error} buttons={['OK']} onSubmit={() => window.location.href = '/'} />
      </Router>
    </ThemeProvider>
  );
};

export default App;
