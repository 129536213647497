import React, { useState, MouseEvent } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import './ImageCarousel.scss';

export interface ImageInfo {
  url: string;
  tooltip: string;
}

interface ImageCarouselProps {
  images: ImageInfo[];
  className?: string;
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images, className }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const handlePrev = (event: MouseEvent) => {
    event.preventDefault();
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNext = (event: MouseEvent) => {
    event.preventDefault();
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className={`carousel-container ${className}`}>
      <IconButton onClick={handlePrev} className='arrowButton left-button'>
        <ArrowBackIos />
      </IconButton>
      <div className='image-container'>
        <Tooltip title={images[currentIndex]?.tooltip} placement='top' arrow>
          <img src={images[currentIndex]?.url} alt={images[currentIndex]?.tooltip} className='responsive-image' />
        </Tooltip>
      </div>
      <IconButton onClick={handleNext} className='arrowButton right-button'>
        <ArrowForwardIos />
      </IconButton>
    </div>
  );
};

export default ImageCarousel;
