import { IconButton, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import styles from "./SearchField.module.scss";

interface SearchFieldProps {
  searchStr: string;
  clearSearch: () => void;
  setSearchStr: React.Dispatch<React.SetStateAction<string>>;
  className?: string;
}

export function SearchField({ clearSearch, searchStr, setSearchStr, className }: SearchFieldProps) {
  return (
    <div className={`${styles.searchDiv} ${className}`}>
      <TextField label="Search" variant='filled' className={styles.searchField} value={searchStr} autoFocus
        onChange={(event) => setSearchStr(event.target.value)}
        InputProps={{
          endAdornment: (
            <IconButton type="submit" onClick={clearSearch}>
              <CloseIcon />
            </IconButton>
          )
        }}
      />
    </div>
  );
}
