import { TripSummary } from "@shared/types/summary";
import { Location } from "react-router-dom";

export function shuffleArray(featuredTrips: any[]): any[] {
  const shuffledArray = featuredTrips.slice();

  for(let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}

// spread out the trips so trips of same country is not close to each other.
export function getDistributedCountryTrips(trips: TripSummary[]): TripSummary[] {
  let list: TripSummary[] = [];

  while(trips.length > 0) {
    list = list.concat(getDistributedDestinations(trips));
  }

  return list;
}

// extract a list of trips with only unique destinations or at minDist from the last occurance. 
// remove the extracted trips from the original list.
function getDistributedDestinations(trips: TripSummary[], minDist = 20): TripSummary[] {
  const list: TripSummary[] = [];
  const countryPosition = new Map<string, number>();

  for(let i = 0; i < trips.length; i++) {
    const countries = trips[i].destinations.split(',').map(c => c.trim());
    const pos = Math.min(...countries.map(c => countryPosition.get(c) || -1))

    if(pos < 0 || pos < i - minDist) {
      countries.forEach(c => countryPosition.set(c, list.length));
      list.push(trips[i]);
      trips.splice(i--, 1);
    }
  }

  return list;
}

export function getLocalStorageValue(key: string, defaultValue: any = '') {
  const saved = localStorage.getItem(key);
  return saved !== null ? saved : defaultValue;
};

// return a new state with current url pushed to returnUrls
export function pushReturnUrl(location: Location): any {
  const state = location.state || {};
  const returnUrls = (state.returnUrls ?? []).concat([]);
  returnUrls.push(`${location.pathname}${location.search}`);
  return {
    ...state,
    returnUrls: returnUrls
  };
}

// get the returnUrl from state and the state to use for when going back to returnUrl
export function popReturnUrl(location: Location): { returnUrl: string, returnState: any } {
  const returnUrls: string[] = (location.state?.returnUrls ?? []).concat([]);
  const returnUrl = returnUrls.pop() || '';
  return {
    returnUrl: returnUrl,
    returnState: {
      returnUrls: returnUrls
    }
  }
}

export function containsWordBoundary(text: string, searchStr: string, wholeWord: boolean): boolean {
  // Escape the search string to treat it literally in the regex
  const escapedSearchStr = searchStr.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regex = new RegExp(`\\b${escapedSearchStr}${wholeWord ? '\\b' : ''}`, 'i');
  return regex.test(text);
}

