import axios from 'axios';
import { Button, Card, CardContent, Snackbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { AppToolbar } from '../component/AppToolbar';
import './AdminPage.scss';
import { SeoInfo } from '@shared/types/admin';
import MessageField from '../widget/MessageField';

export function AdminPage() {
  const [message, setMessage] = useState<string>('');
  const [sitemapTS, setSitemapTS] = useState<Date>();

  useEffect(() => {
    axios.get<SeoInfo>('/get-seo').then(result => {
      setSitemapTS(result.data.sitemapTS);
    });
  }, []);

  function generateSeo() {
    setMessage('Generating SEO files...');
    axios.post('/generate-seo').then(msg => {
      setMessage(msg.data.message);
    }).catch(ex => setMessage(`Failed to generate SEO files: ${ex}`))
  }

  function restart() {
    setMessage('Restarting...');
    axios.post('/restart').then(msg => {
      setMessage(msg.data.message);
    }).catch(ex => setMessage(`Failed to restart: ${ex}`))
  }

  return (
    <div className='admin-container'>
      <AppToolbar current='admin'/>
      <div className='admin-content'>
        <div className='section-title'>Administration</div>
        <Card>
          <CardContent>
            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 16 }}>
              SEO
            </Typography>
            <div className='seo-container'>
              <div className='sitemap-info'>
                {sitemapTS ? `Sitemap generated at: ${sitemapTS}` : 'Sitemap not generated'}
              </div>
              <Button onClick={generateSeo} variant='contained'>Generate SEO</Button>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 16 }}>
              Broadcast Message
            </Typography>
            <MessageField />
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 16 }}>
              Restart
            </Typography>
            <div className='seo-container'>
              <Button onClick={restart} variant='contained'>Restart in 5 Minutes</Button>
            </div>
          </CardContent>
        </Card>
      </div>
      <Snackbar open={!!message} message={message} />
    </div>
  );
};