import { Plan, PlanEvent, TimeOfDay, EventType, PlanDay } from "@shared/types/plan";
import { BigCalendarEvent, getTravelPlan } from "./calendarUtil";
import { Trip, TripDay } from "@shared/types/itinerary";
import { getLocationCity, getLocationsString } from "trip-util";
import { spreadEvents } from "./spreadEvents";

export function createEventsFromPlan(plan: Plan, overview: boolean): BigCalendarEvent[] {
  if(!plan.start) {
    return [];
  }

  const start = plan.start;

  // find time available for attractions (subtract travel time if arriving or leaving on the day)
  function findTime(events: PlanEvent[]): { hour?: number; before?: boolean; } {
    for(let event of events) {
      if(event.timeOfDay instanceof Date) {
        let hour: number = event.timeOfDay.getHours() + event.timeOfDay.getMinutes() / 60;
        let beforeTime = 0, afterTime = 0;

        switch(event.eventType) {
          case 'travel-air':
            beforeTime = 2.;
            afterTime = 1.5;
            break;
          case 'travel-train':
            beforeTime = 1;
            afterTime = 0.5;
            break;
        }

        // arriving to this location
        if(event.arriving) {
          hour += (event.duration || 0) + afterTime;
          // round to 1/2 hour
          hour = Math.ceil(hour * 2) / 2;
        }

        // leaving this location
        else {
          hour -= beforeTime;
          // round to 1/2 hour
          hour = Math.floor(hour * 2) / 2;
        }

        return {
          hour: hour,
          before: !event.arriving
        };
      }
    }

    return {};
  }

  return plan?.days?.map((day, idx) => {
    if(overview) {
      return [{
        title: day.title,
        dayTrip: day.dayTrip,
        notes: day.notes,
        ...getEventTime(start, idx),
        timeOfDay: 'all day' as TimeOfDay,
        eventType: 'overview' as EventType,
        dayIndex: idx,
        eventIndex: 0,
        allDay: true
      }];
    }

    const { hour, before } = findTime(day.events);
    // add time for sorting
    let eventsWithTime = day.events.map((a, eidx) => ({
      ...a,
      ...getEventTime(start, idx, a.eventType, a.timeOfDay, a.duration, before, hour, day.events[eidx + 1])
    })).sort((a, b) => a.start.getTime() - b.start.getTime());
    eventsWithTime = spreadEvents(eventsWithTime);

    // remove start/end
    day.events = eventsWithTime.map(e => {
      const { start, end, ...event } = e;
      return event;
    });

    const tooltip = (event: PlanEvent) => {
      const eventLocation = event.location || day.dayTrip || day.title;
      return day.dayTrip && day.dayTrip !== event.location ? `${event.title}, ${getLocationCity(eventLocation)}`
        : event.title;
    }

    return eventsWithTime.map((a, i) => ({
      ...a,
      dayIndex: idx,
      eventIndex: i,
      allDay: a.timeOfDay === 'all day' && a.eventType === 'activity'
    })).map(event => {
      const travelPlan = !overview ? getTravelPlan(event, event.location || day.title) : '';
      return {
        ...event,
        tooltip: `${tooltip(event)}${travelPlan ? '\n' + travelPlan : ''}`
      };
    });
  }).flat() ?? [];
}

export function createPlanFromTrip(trip: Trip, start?: Date, plan?: Plan): Plan {
  let days: PlanDay[] = trip?.itinerary?.map((tripDay, idx) => findPlanDay(idx, tripDay, plan) ?? ({
    title: tripDay.overnightCity,
    notes: tripDay.notes || (plan ? findDayNotes(tripDay.overnightCity, plan) : undefined),
    fromItinerary: true,
    itineraryDay: tripDay.day,
    dayTrip: tripDay.overnightCity !== getLocationsString(tripDay) ? getLocationsString(tripDay, true) : undefined,
    events: tripDay.attractions.map((a, i) => ({
      title: a.place,
      notes: (plan ? findEventNotes(tripDay.overnightCity, a.place, plan) : null) || '',
      timeOfDay: i < tripDay.attractions.length / 2 ? 'morning' : 'afternoon',
      fromItinerary: true,
      location: a.location,
      eventType: 'activity' as EventType,
    }) as PlanEvent).concat(findUserEvents(idx, plan))
  })) ?? [];
  const itineraryLength = days.length;
  const olength = plan?.itineraryLength || itineraryLength;

  function findPlanDay(i: number, tripDay: TripDay, plan?: Plan): PlanDay | undefined {
    const planDay = plan?.days?.[i + (plan?.itineraryOffset ?? 0)];
    if(planDay?.title === tripDay.overnightCity && planDay.dayTrip === getLocationsString(tripDay, true)) {
      const oevents = planDay.events.map(e => `${e.title},${e.location}`);
      const tevents = tripDay.attractions.map(e => `${e.place},${e.location}`);

      if(tevents.every(n => oevents.includes(n))) {
        return {
          ...planDay,
          fromItinerary: true,
          itineraryDay: tripDay.day,
          notes: tripDay.notes || planDay.notes,
          events: planDay.events
            // don't include event if removed from itinerary
            .filter(event => !event.fromItinerary || tevents.includes(event.title))
            .map(event => ({
              ...event,
              fromItinerary: tevents.includes(event.title)
            }))
        };
      }
    }
  }

  function findDayNotes(dayTitle: string, oplan: Plan): string | undefined {
    for(const oday of oplan.days) {
      if(oday.title === dayTitle) {
        return oday.notes;
      }
    }
  }

  function findEventNotes(dayTitle: string, eventTitle: string, oplan: Plan): string | undefined {
    for(const oday of oplan.days) {
      if(oday.title === dayTitle) {
        for(const oevent of oday.events) {
          if(oevent.title === eventTitle) {
            return oevent.notes;
          }
        }
      }
    }
  }

  function findUserEvents(tripDayIdx: number, plan?: Plan): PlanEvent[] {
    // copy the user events or notes from existing plan
    if(trip.itinerary?.length && plan && (!plan.itineraryLength || plan.itineraryLength === trip.itinerary.length)) {
      const planIdx = tripDayIdx + plan.itineraryOffset;

      if(trip.itinerary[tripDayIdx].overnightCity === plan.days[planIdx].title) {
        return plan.days[planIdx].events.filter(event => !event.fromItinerary);
      }
    }

    return [];
  }

  if(plan?.itineraryOffset) {
    days = plan.days.slice(0, plan.itineraryOffset)
      .concat(days)
      .concat(plan.days.slice(plan.itineraryOffset + olength));
  }

  return {
    itineraryOffset: plan?.itineraryOffset || 0,
    itineraryLength: itineraryLength,
    start: start,
    days: days
  };
}

function getEventTime(date: Date = new Date(), dayIncr: number = 0,
  eventType: EventType = 'activity', timeOfDay: TimeOfDay = 'morning', duration0?: number,
  before: boolean = false, beforeHour?: number, nextEvent?: PlanEvent): { start: Date; end: Date; } {
  function createDate(date: Date, dayIncr: number, hour: number, minute: number): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + dayIncr, hour, minute, 0);
  }

  let startHourMinute = 8, endHourMinute = 12, duration = duration0 || 4;
  const food = eventType === 'food';

  switch(timeOfDay) {
    case 'morning':
      if(food) {
        startHourMinute = 7.5;
        endHourMinute = 8;
      }
      else {
        startHourMinute = 8;
        endHourMinute = 12;
      }
      break;
    case 'afternoon':
      if(food) {
        startHourMinute = 12;
        endHourMinute = 13;
      }
      else {
        startHourMinute = 13;
        endHourMinute = 18;
      }
      break;
    case 'evening':
      if(food) {
        startHourMinute = 18;
        endHourMinute = 19;
      }
      else {
        startHourMinute = 19;
        endHourMinute = 22;
      }
      break;
    default:
      if(timeOfDay instanceof Date) {
        startHourMinute = timeOfDay.getHours() + timeOfDay.getMinutes() / 60;
        endHourMinute = startHourMinute + duration;
      }
      else {
        if(food) {
          if(nextEvent?.timeOfDay === 'morning') {
            startHourMinute = 7.5;
            endHourMinute = 8;
          }
          else if(nextEvent?.timeOfDay === 'afternoon') {
            startHourMinute = 12;
            endHourMinute = 13;
          }
          else {
            startHourMinute = 18;
            endHourMinute = 19;
          }
        }
        else {
          startHourMinute = 8;
          endHourMinute = 18;
          duration = 10;
        }
      }
  }

  if(beforeHour && !(timeOfDay instanceof Date)) {
    // event extends after the beforeHour (leaving for airport), move it earlier
    if(before && endHourMinute > beforeHour) {
      endHourMinute = beforeHour;
      startHourMinute = Math.min(Math.max(8, endHourMinute - duration), endHourMinute);
    }

    // event happens before arriving, move it later
    else if(!before && startHourMinute < beforeHour) {
      startHourMinute = beforeHour;
      endHourMinute = Math.max(startHourMinute, Math.min(22, startHourMinute + duration));
    }
  }

  if(eventType === 'hotel') {
    startHourMinute = 22;
    endHourMinute = 23;
  }

  const startMinute = Math.floor(startHourMinute * 60 % 60);
  const endMinute = Math.floor(startHourMinute * 60 % 60);
  return {
    start: createDate(date, dayIncr, Math.floor(startHourMinute), startMinute),
    end: createDate(date, dayIncr, Math.floor(endHourMinute), endMinute)
  };
}

