import { getTransportationLocations } from "trip-util";
import { Trip } from "../../../../shared/types/itinerary";
import { getTransportationInfo } from "../../service/chatRequests";

export function updateTransportationInfo(trip: Trip): Promise<Trip | undefined> {
  if(trip.itinerary?.length) {
    // when itinerary is changed by user (delete/day-trip/arrange), the transportation information in the
    // original trip may not be correct. update the instruction from AI.
    return getTransportationInfo(trip).then(info => {
      if(trip.itinerary?.length) {
        for(let i = 1; i < trip.itinerary.length; i++) {
          const locs = getTransportationLocations(trip, i);
          const instructions: string[] = [];

          for(let k = 1; k < locs.length; k++) {
            const prevStay = locs[k - 1];
            const to = locs[k];
            const instruction = info.transportations
              .filter(t => t.fromLocation === prevStay && t.toLocation === to)
              .map(t => t.instruction)[0];
            instructions.push(instruction);
          }

          trip.itinerary[i].transportation = instructions.filter(a => !!a).join('\n');
        }

        return { ...trip };
      }
    });
  }

  return Promise.resolve(undefined);
}