import { TripSummary } from "@shared/types/summary";
import { useEffect, useState } from 'react';
import { useTripCache } from '../service/TripCache';
import './FeaturedTrips.scss';
import { AppToolbar } from '../component/AppToolbar';
import { FeaturedTripItem } from '../component/feature/FeaturedTripItem';
import { Backdrop, CircularProgress, Fab, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { capitalizeWords, getCountryInfo, getRegionPath, TravelRegion } from 'trip-util';
import { SearchField } from "../component/trips/SearchField";
import SearchIcon from '@mui/icons-material/Search';
import { containsWordBoundary, getDistributedCountryTrips, shuffleArray } from "../util/ui-util";
import { useNavigate, useSearchParams } from "react-router-dom";

interface FeaturedTripsProps {
  region: TravelRegion;
}

export function FeaturedTrips(props: FeaturedTripsProps) {
  const { region } = props;
  const [searchParams] = useSearchParams();
  const getSearchStr = () => searchParams.get('search') || '';
  const [featuredTrips, setFeaturedTrips] = useState<TripSummary[]>();
  const tripService = useTripCache();
  const [searchStr, setSearchStr] = useState<string>(() => getSearchStr());
  const [searching, setSearching] = useState<boolean>(() => !!getSearchStr());
  const [selectedRegion, setSelectedRegion] = useState<TravelRegion>('all');
  const regions: TravelRegion[] = ['europe', 'asia', 'north america', 'oceania', 'central & south america', 'others'];
  const navigate = useNavigate();

  useEffect(() => {
    tripService.getFeaturedTrips((trips) => setFeaturedTrips(getDistributedCountryTrips(shuffleArray(trips))));
  }, []);

  useEffect(() => {
    setSelectedRegion(region);
  }, ['region']);

  useEffect(() => {
    const path = selectedRegion === 'all' ? '' : getRegionPath(selectedRegion);
    const search = searchStr ? `?search=${searchStr}` : '';
    navigate(`/featured-trips/${path || ''}${search}`, { replace: true });
  }, [searchStr, selectedRegion]);

  function getTrips(): TripSummary[] {
    if(!searchStr && selectedRegion === 'all') {
      return featuredTrips || [];
    }

    const trips = (featuredTrips || []).filter(t =>
      (selectedRegion === 'all' || selectedRegion === getCountryInfo(t.destinations)?.travelRegion));
    const matchedTitle: TripSummary[] = [];
    const matchedDesc: TripSummary[] = [];

    for(let trip of trips) {
      if(matchTrip(searchStr, trip, true)) {
        matchedTitle.push(trip);
      }
      else if(matchTrip(searchStr, trip, false)) {
        matchedDesc.push(trip);
      }
    }

    return matchedTitle.concat(matchedDesc);
  }

  function clearSearch() {
    setSearchStr('');
    setSearching(false);
  }

  function matchTrip(searchStr: string, trip: TripSummary, matchTitle: boolean) {
    if(!searchStr) {
      return true;
    }

    const text = matchTitle ? `${trip.tripName} ${trip.title}` : `${trip.description}`;
    return containsWordBoundary(text, searchStr, !matchTitle);
  }

  function getRegions(): TravelRegion[] {
    const availableRegions = featuredTrips?.map(t => getCountryInfo(t.destinations)?.travelRegion?.toLowerCase())
      .filter(r => !!r);
    return regions.filter(r => availableRegions?.includes(r));
  }

  function setSelectedRegion0(value: TravelRegion) {
    setSelectedRegion(value);
  }

  return (
    <div className='features-top-container'>
      <AppToolbar current="featuredTrips" />
      {searching ?
        <SearchField clearSearch={clearSearch} searchStr={searchStr} setSearchStr={setSearchStr}
          className='search-field' /> : null
      }
      <Fab className="search-button" size='small' onClick={() => setSearching(!searching)}>
        <Tooltip title='Search'>
          <SearchIcon />
        </Tooltip>
      </Fab>
      {getRegions()?.length > 2 ?
        <ToggleButtonGroup defaultValue={selectedRegion || 'all'} exclusive value={selectedRegion} className="country-group"
          onChange={(event, value) => setSelectedRegion0(value as TravelRegion || 'all')}>
          {getRegions().map((r, idx) => (
            <ToggleButton value={r} key={idx}>{capitalizeWords(r)}</ToggleButton>
          ))}
          <ToggleButton value='all'>All</ToggleButton>
        </ToggleButtonGroup> : null
      }
      <div className={`features-content-container ${searching ? 'searching' : ''}`}>
        {getTrips()?.slice(0, 100).map((t, i) => <FeaturedTripItem trip={t} key={i} />)}
      </div>
      <Backdrop open={!featuredTrips} sx={{ zIndex: 10000 }}>
        <CircularProgress sx={{ color: 'white' }} />
      </Backdrop>
    </div>
  );
}
