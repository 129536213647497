interface Interval {
  start: Date;
  end: Date;
}

// spread out overlapping events.
export function spreadEvents(events: Interval[]): any[] {
  // Sort intervals by start time
  const sortedEvents = events.sort((a, b) => a.start!.getTime() - b.start!.getTime());

  let result: Interval[] = [];
  let overlapGroup: Interval[] = [];

  // Helper function to spread intervals in an overlap group
  const distributeEvents = (group: Interval[]) => {
    if(group.length <= 1) {
      result.push(...group); // No overlap
      return;
    }

    // Find the earliest start and latest end time
    const earliestStart = group[0].start!;
    const latestEnd = group[group.length - 1].end!;

    // Calculate the total duration available to spread across all intervals
    const totalDuration = latestEnd.getTime() - earliestStart.getTime();
    const durationPerEvent = totalDuration / group.length;

    // Spread the intervals evenly
    let currentStart = earliestStart;
    for(let i = 0; i < group.length; i++) {
      const intervalEnd = new Date(currentStart.getTime() + durationPerEvent);

      // Update the interval
      group[i].start = currentStart;
      group[i].end = intervalEnd;

      // Move to the next start time
      currentStart = intervalEnd;
    }

    // Add the spread-out group to the result
    result.push(...group);
  };

  // Iterate through sorted intervals and find overlapping groups
  for(let i = 0; i < sortedEvents.length; i++) {
    const currentEvent = sortedEvents[i];

    if(overlapGroup.length === 0) {
      overlapGroup.push(currentEvent);
    } else {
      const lastEventInGroup = overlapGroup[overlapGroup.length - 1];
      
      // Check if the current interval overlaps with the previous one
      if(lastEventInGroup.end! > currentEvent.start!) {
        overlapGroup.push(currentEvent);
      } else {
        // Process the previous overlap group and start a new group
        distributeEvents(overlapGroup);
        overlapGroup = [currentEvent];
      }
    }
  }

  // Handle the last group of intervals
  if(overlapGroup.length > 0) {
    distributeEvents(overlapGroup);
  }

  return result;
}