import React, { useEffect, useState } from 'react';
import "./BroadcastMessage.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const BroadcastMessage: React.FC = () => {
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    const serverUrl = process.env.REACT_APP_EXPRESS_SERVER_URL;
    const eventSource = new EventSource(`${serverUrl}/message`);

    eventSource.onmessage = (event) => {
      const newMessage = JSON.parse(event.data);
      setMessage(newMessage.message || newMessage.time);
    };

    // Cleanup when the component unmounts
    return () => eventSource.close();
  }, []);

  return message ?
    <div className='broadcast-message-container'>
      <span>
        {message}
      </span>
      <div className='close-icon' onClick={() => setMessage('')}>
        <FontAwesomeIcon icon={faClose} />
      </div>
    </div > : null;
};

export default BroadcastMessage;
