import React from 'react';
import axios from 'axios';
import { Alert, Button } from '@mui/material';

interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ error, resetErrorBoundary }) => {
  axios.post('/log-error', {
    message: error.message,
    stack: error.stack
  });
  console.log("Internal Error:", error);
  alert("Internal Error: " + error.message);

  return (
    <Alert>
      <h5>Internal Error:</h5>
      {error.message}
      <Button onClick={resetErrorBoundary}>Try again</Button>
    </Alert>
  );
};

export default ErrorFallback;
