import React, { KeyboardEvent, useEffect, useState } from 'react';
import { TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, Switch, FormControlLabel } from '@mui/material';

interface SaveDialogProps {
  open: boolean,
  newTrip: boolean,
  readonly: boolean,
  inputValue?: string,
  onClose?: () => void,
  onSubmit: (value: string, saveAs: boolean) => void;
};

export function SaveDialog(props: SaveDialogProps) {
  const { open, newTrip, readonly, onClose, onSubmit } = props;
  const [inputValue, setInputValue] = useState<string>('');
  const [saveAs, setSaveAs] = useState<boolean>(newTrip);

  useEffect(() => {
    setInputValue(props.inputValue ?? '')
  }, [props.inputValue]);

  const handleChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    onSubmit(inputValue, saveAs);
    onClose && onClose();
  };

  const handleClose = () => {
    onClose && onClose();
  };

  function keyDown(event: KeyboardEvent) {
    if(event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Save Itinerary</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField sx={{ width: '50vw', maxWidth: '500px' }} autoFocus
            placeholder="7 Days in Paris" value={inputValue}
            onKeyDown={keyDown} onChange={handleChange} variant='outlined' fullWidth />
          {!newTrip ?
            <FormControlLabel label="As a New Itinerary" disabled={readonly}
              control={<Switch checked={saveAs || readonly} onChange={() => setSaveAs(!saveAs)} />} /> : null
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button variant='contained' onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveDialog;
