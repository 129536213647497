import { useEffect, useState } from "react";
import { Trip, TripDay } from "../../../../shared/types/itinerary";
import { Mapbox, MapPoint } from "./Mapbox";
import "./TripMap.scss";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlassMinus } from "@fortawesome/free-solid-svg-icons";
import { mergeMapPoints } from "./mergeMapPoints";
import { getImagePlace } from "trip-util";

interface TripMapProps {
  trip: Trip,
  selectedDays: Set<number>
  onSelect?: (day: TripDay) => void;
  onClear?: () => void;
}

export function TripMap(props: TripMapProps) {
  const [markers, setMarkers] = useState<MapPoint[]>([]);
  const { trip, selectedDays, onSelect, onClear } = props;

  function getMapPoints(trip: Trip, selectedDays: Set<number>): MapPoint[] | undefined {
    if(selectedDays.size > 0 && trip?.itinerary) {
      const points = Array.from(selectedDays)
        .filter(selectedDay => selectedDay < trip.itinerary!.length)
        .map(selectedDay => trip.itinerary![selectedDay].attractions).flat()
        .filter(a => !!a.latitude && !!a.longitude)
        .map(a => {
          const placeFull = getImagePlace(a.place, a.location);
          const imageUrl = `/image?place=${placeFull}`;
          return {
            lat: a.latitude,
            lng: a.longitude,
            location: a.place + ', ' + a.location,
            tip: a.place,
            image: imageUrl,
            secondary: true
          }
        });
      return mergeMapPoints(points);
    }

    function compressNumbers(nums: number[]): string {
      if(nums.length === 0) {
        return '';
      }

      let result = '';
      let start = nums[0];
      let end = nums[0];

      for(let i = 1; i < nums.length; i++) {
        if(nums[i] === end + 1) {
          end = nums[i];
        } else {
          if(start === end) {
            result += `${start},`;
          } else {
            result += `${start}-${end},`;
          }
          start = nums[i];
          end = nums[i];
        }
      }

      // Add the last range or single number
      if(start === end) {
        result += `${start}`;
      } else {
        result += `${start}-${end}`;
      }

      return result;
    }

    function getDays(day: TripDay, location: string): number[] | undefined {
      return trip?.itinerary
        ?.map((d, idx) => ({ ...d, day: idx + 1 }))
        ?.filter(d => d.overnightCity === location || d.locations.find(loc => loc.location === location))
        ?.map(d => d.day);
    }

    function getTip(day: TripDay, location: string): string {
      const days = getDays(day, location);
      return days && days.length > 1 ? `${location}<br>Day: ${compressNumbers(days)}` : location;
    }

    return trip?.itinerary
      ?.map(d => d.locations?.map(location => ({
        day: d,
        location: location
      })))
      ?.filter(a => !!a)
      ?.flat()
      ?.filter(d => !!d.location.latitude && !!d.location.longitude)
      ?.map(d => ({
        lat: d.location.latitude,
        lng: d.location.longitude,
        num: d.day.day + '',
        location: d.location.location,
        tip: getTip(d.day, d.location.location),
        days: getDays(d.day, d.location.location)?.length || 1,
        secondary: d.location.location !== d.day.overnightCity,
        click: onSelect ? () => onSelect(d.day) : undefined
      }));
  }

  useEffect(() => {
    setMarkers(getMapPoints(trip, selectedDays) || []);
  }, [trip, selectedDays]);

  return (
    <div className="trip-map-container">
      <Mapbox style={{ flex: 1 }} centerLat={0} centerLng={0} width='500px' height='100%'
        markers={markers} maxZoom={5} />;
      {selectedDays.size > 0 ?
        <IconButton className="clear-button" onClick={() => onClear && onClear()}>
          <FontAwesomeIcon icon={faMagnifyingGlassMinus}></FontAwesomeIcon>
        </IconButton> : null
      }
    </div>);
}

