// CustomCursor.tsx
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane } from '@fortawesome/free-solid-svg-icons';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';

interface CustomCursorProps {
  icon?: IconProp; // Optional prop to specify a different Font Awesome icon
  size?: SizeProp; // Optional prop to customize icon size
  color?: string; // Optional prop to customize icon color
}

const CustomCursor: React.FC<CustomCursorProps> = ({ icon = faPlane, size = 'xl', color = 'black' }) => {
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setX(event.clientX);
      setY(event.clientY);
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  return (
    <div style={{
        position: 'fixed',
        top: y,
        left: x,
        pointerEvents: 'none',
        zIndex: 9999,
      }}
    >
      <FontAwesomeIcon icon={icon} size={size} color={color} />
    </div>
  );
};

export default CustomCursor;