import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { TooltipProps } from '@mui/material/Tooltip';

interface CustomTooltipProps extends TooltipProps { }

const CustomTooltip: React.FC<CustomTooltipProps> = ({ children, ...props }) => {
  return (
    <Tooltip
      // fit text and wrap
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: '80vw',
            whiteSpace: 'pre-line'
          }
        }
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
