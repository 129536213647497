import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEvent, useState } from 'react';
import { Event } from 'react-big-calendar';
import "./CustomEvent.scss";
import { EventMenuButton } from './EventMenuButton';
import { BigCalendarEvent, getDayCellLabel, mapEvent, searchEvent } from './calendarUtil';
import { EventType } from '../../../../shared/types/plan';
import { CustomEventIcon } from './CustomEventIcon';
import { faCircleDown, faCircleUp } from '@fortawesome/free-solid-svg-icons';
import CustomTooltip from '../../widget/CustomTooltip';

export interface CustomEventProps {
  event: Event;
  overview?: boolean;
  location?: string;
  prevLocation?: string;
  typeOfAttraction?: string;
  eventType?: EventType;
  hasNotes?: boolean;
  onDelete?: (event: BigCalendarEvent) => void;
  onEdit?: (event: BigCalendarEvent) => void;
  onUp?: (event: BigCalendarEvent) => void;
  onDown?: (event: BigCalendarEvent) => void;
}

export function CustomEvent(props: CustomEventProps) {
  const { event, overview, location, onDelete, onEdit, onUp, onDown } = props;
  const [clickHandler, setClickHandler] = useState<NodeJS.Timeout>();
  const bigEvent = event as BigCalendarEvent;

  function handleUp(mouseEvent: MouseEvent) {
    mouseEvent.stopPropagation();
    onUp && onUp(event as BigCalendarEvent);
  }

  function handleDown(mouseEvent: MouseEvent) {
    mouseEvent.stopPropagation();
    onDown && onDown(event as BigCalendarEvent);
  }

  function doubleClicked() {
    if(clickHandler) {
      clearTimeout(clickHandler);
      setClickHandler(undefined);
    }

    onEdit && onEdit(event as BigCalendarEvent);
  }

  function clicked(event0: MouseEvent) {
    if(event0.altKey) {
      mapEvent(bigEvent, location!);
    }
    else if(!clickHandler) {
      const handler = setTimeout(() => {
        searchEvent(bigEvent, location!);
      }, 300);
      setClickHandler(handler);
    }
  }

  function getTooltip(): any {
    return <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ fontSize: 15, whiteSpace: 'pre-line' }}> {bigEvent.tooltip} </div>
      <div>Click: Search, Alt-Click: Map, 2x Click: Edit, Drag: Move</div>
    </div>
  }

  return (
    <CustomTooltip placement='right' arrow disableInteractive title={getTooltip()}>
      <div className={`custom-event ${overview ? 'day-overview' : ''}`}
        onClick={clicked} onDoubleClick={doubleClicked}>
        {onDelete && onEdit && !onUp && !onDown ?
          <EventMenuButton onDelete={onDelete} onEdit={onEdit} event={event as BigCalendarEvent}
            location={location!} /> : null
        }
        {onUp || onDown ?
          <FontAwesomeIcon icon={faCircleUp} className={`order-icon ${!onUp ? 'disabled-icon' : ''}`}
            onClick={handleUp} /> : null
        }
        {onDown || onUp ?
          <FontAwesomeIcon icon={faCircleDown} className={`order-icon ${!onDown ? 'disabled-icon' : ''}`}
            onClick={handleDown} /> : null
        }
        {!onUp && !onDown ? <CustomEventIcon {...props} /> : null}
        <span>{getDayCellLabel(bigEvent.title, bigEvent.dayTrip)}</span>
      </div>
    </CustomTooltip>
  );
}
