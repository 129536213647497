import { List, Stack, Chip, Divider, Tooltip, Typography } from "@mui/material";
import { TripDay } from "../../../../shared/types/itinerary";
import { AttractionComponent } from './AttractionComponent';
import "./TripDayComponent.scss";
import {
  faMagnifyingGlassMinus, faMagnifyingGlassPlus, faTrain
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { getLocationCity, getTripDayTitle } from "trip-util";
import { TripDayMenuButton } from "./TripDayMenuButton";
import DayDialog from "./DayDialog";
import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import CustomTooltip from "../../widget/CustomTooltip";
import { ViewMode } from "../../page/TripChat";

interface TripDayComponentProps {
  day: TripDay;
  selected?: boolean;
  prevStay?: string;
  viewMode?: ViewMode;
  attractionNumber?: number;
  onSelect: (event: React.MouseEvent) => void;
  onDelete: () => void;
  onDayTrip: (day: TripDay, prevStay: string) => void;
  onBase: (day: TripDay) => void;
  onAdd: (day: TripDay) => void;
  onChange: (day: TripDay, instruction?: string) => void;
  onReplaceAttraction: (day: TripDay, index: number) => void;
  onMoveAttraction: (fromDay: number, fromAttraction: number, toDay: number, toAttraction: number) => void;
}

function TripDayComponent0(props: TripDayComponentProps) {
  const { day, selected, prevStay, viewMode, attractionNumber, onSelect, onDelete, onDayTrip, onBase, onAdd,
    onChange, onReplaceAttraction, onMoveAttraction } = props;
  const locationCity = getLocationCity(day.locations[0].location);
  const overnightCity = getLocationCity(day.overnightCity);
  const prevCity = getLocationCity(prevStay);
  const [editDay, setEditDay] = useState<TripDay>();

  function getZoomLabel() {
    return <div style={{ whiteSpace: 'pre-line' }}>
      Zoom {!selected ? 'into' : 'out of'} Day {day.day}.
      {selected ? '\nAlt-Click to clear all.' : '\nAlt-Click to select all. Ctrl-Click to select more.'}
    </div>;
  }

  function getOvernightCity(day: TripDay): string {
    const title = getTripDayTitle(day);
    const p1 = title.indexOf('(');
    const p2 = title.indexOf(')', p1 + 1);

    return p2 > p1 && p1 > 0 ? title.substring(p1 + 1, p2) : '';
  }

  function deleteAttraction(index: number) {
    const deleted = day.attractions.splice(index, 1);
    const attractionLocs = day.attractions.map(a => a.location);
    day.locations = day.locations.filter(loc => attractionLocs.includes(loc.location));
    onChange(day, `Remove ${deleted[0].place} from day ${day.day}`);
  }

  const tooltip = day.notes ?
    <Typography fontSize={12} style={{ whiteSpace: 'pre-line' }}>{day.notes}</Typography>
    : null;

  return (
    <Stack className={`trip-day-container ${selected ? 'selected' : ''} ${viewMode === 'dayOverview' ? 'draggable' : ''}`}>
      <div className="title-container">
        <div className="day-label">
          <Chip label={'Day ' + day.day} variant='filled' sx={{ fontWeight: "bold" }} />
        </div>
        <div className="title-label-div">
          <CustomTooltip title={tooltip}>
            <div className="title-label">
              {
                day.locations.map((loc, idx) => (
                  <a href={`https://www.google.com/search?q=${loc.location} attractions`} target='_blank' key={idx}>
                    <div className="tripday-location">{idx > 0 ? '/' : ''}{getLocationCity(loc.location)}</div>
                  </a>
                ))
              }
              {
                getOvernightCity(day) ?
                  <a href={`https://www.google.com/search?q=${day.overnightCity} attractions`} target='_blank'>
                    <div className="tripday-location">({getOvernightCity(day)})</div>
                  </a> : null
              }
              {day.notes ?
                <FontAwesomeIcon icon={faCommentDots} className="notes-icon" onClick={() => setEditDay(day)} /> : null}
            </div>
          </CustomTooltip>
        </div>
        <div className="icon-container">
          {prevStay && prevStay !== day.locations[0].location || day.transportation ?
            <CustomTooltip title={day.transportation} disableInteractive className="button-icon">
              <a href={prevStay ? `https://www.rome2rio.com/s/${prevStay}/${day.locations[0].location}` : ""}
                target="trip_map" >
                <FontAwesomeIcon icon={faTrain} size="lg" className="transportation-icon" />
              </a>
            </CustomTooltip> : null
          }
          <Tooltip title={getZoomLabel()} className="button-icon" disableInteractive
            onClick={(event) => onSelect(event)}>
            {selected ? <FontAwesomeIcon icon={faMagnifyingGlassMinus} size="lg" />
              : <FontAwesomeIcon icon={faMagnifyingGlassPlus} size="lg" />}
          </Tooltip>
          <TripDayMenuButton
            onAdd={() => onAdd(day)}
            onDelete={() => onDelete()}
            onDayTrip={prevStay && prevCity !== overnightCity ? () => onDayTrip(day, prevStay) : undefined}
            onOvernight={prevStay && prevCity === overnightCity && locationCity !== overnightCity ? () => onBase(day) : undefined}
            onEdit={() => setEditDay(day)}
          />

        </div>
      </div>
      {viewMode === 'dayOverview' && !selected ? null :
        <List>
          {day.attractions.map((activity, index) => (
            <div key={index}>
              <AttractionComponent activity={activity} multiLocation={day.locations.length > 1}
                compact={viewMode === 'compact'} day={day} attractionIndex={index}
                attractionNumber={attractionNumber !== undefined ? attractionNumber + index : undefined}
                onDelete={() => deleteAttraction(index)}
                onReplaceAttraction={() => onReplaceAttraction(day, index)}
                onDropAttraction={(fromDay, fromAttr, before) =>
                  onMoveAttraction(fromDay, fromAttr, day.day - 1, index + (before ? 0 : 1))}
              />
              {index < day.attractions.length - 1 && viewMode === 'detail' ?
                <Divider sx={{ marginBottom: 1 }} /> : null
              }
            </div>
          ))}
        </List>
      }
      <DayDialog open={!!editDay} day={day} onClose={() => setEditDay(undefined)}
        onSubmit={(notes) => onChange({ ...day, notes })} />
    </Stack>
  );
}

export const TripDayComponent = React.memo(TripDayComponent0);
