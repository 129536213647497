import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import "./DayMenuButton.scss";
import { PlanDay } from '../../../../shared/types/plan';
import { MenuButton } from '../../widget/MenuButton';

interface DayMenuButtonProps {
  date: Date;
  planDay?: PlanDay;
  tooltip?: any;
  className?: string;
  onAdd?: (date: Date, planDay?: PlanDay) => void;
  onEdit?: (date: Date, planDay?: PlanDay) => void;
  onDelete?: (date: Date) => void;
  onOptimize?: (date: Date, planDay?: PlanDay) => void;
  onOrder?: (date: Date) => void;
}

export function DayMenuButton(props: DayMenuButtonProps) {
  const { date, planDay, tooltip, className, onEdit, onAdd, onDelete, onOptimize, onOrder } = props;
  const actions = [];

  if(onAdd) {
    actions.push({
      label: 'Add Event',
      icon: <AddCircleIcon />,
      action: () => onAdd(date, planDay)
    });
  }

   if(onEdit) {
    actions.push({
      label: 'Edit Location',
      icon: <EditCalendarIcon/>,
      action: () => onEdit(date, planDay)
    });
  }

  if(onOptimize) {
    actions.push({
      label: 'Optimize Route',
      icon: <AutoFixHighIcon/>,
      action: () => onOptimize(date, planDay)
    });
  }

  if(onOrder) {
    actions.push({
      label: 'Re-order Event',
      icon: <SwapVerticalCircleIcon/>,
      action: () => onOrder(date)
    });
  }

  if(onDelete) {
    actions.push({
      label: 'Remove Day',
      icon: <DeleteIcon/>,
      action: () => onDelete(date)
    });
  }

  return MenuButton({ actions, className, tooltip });
}
