import { Divider, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { EventType } from "../../../../shared/types/plan";
import { LocalizationProvider, renderTimeViewClock, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from 'dayjs';
import "./EventTravelComponent.scss";

interface EventTravelComponentProps {
  locationCity: string;
  arriving?: boolean;
  setArriving: (arriving: boolean|undefined) => void;
  travelType?: EventType;
  setTravelType: (travelType: EventType) => void;
  travelTime?: Dayjs|null,
  setTravelTime: (travelTime: Dayjs | null) => void;
  duration?: number|string;
  setDuration: (duration: number|string) => void;
}

export function EventTravelComponent(props: EventTravelComponentProps) {
  const { locationCity, arriving, setArriving, travelType, setTravelType,
    travelTime, setTravelTime, duration, setDuration } = props;

  return (
    <div className="event-travel-component">
      <FormControl>
        <Divider/>
        <RadioGroup value={!!arriving} row={true} className='radio-group'
          onChange={(event) => setArriving(event?.target?.value === 'true')}>
          <FormControlLabel value={false} control={<Radio />} label={`Departing ${locationCity}`}
            className='radio-button' />
          <FormControlLabel value={true} control={<Radio />} label={`Arriving at ${locationCity}`}
            className='radio-button' />
        </RadioGroup>
      </FormControl>
      <FormControl>
        <RadioGroup value={travelType} row={true} className='radio-group'
          onChange={(event) => setTravelType(event?.target?.value as EventType)}>
          <FormControlLabel value='travel-air' control={<Radio />} label='Air' className='radio-button' />
          <FormControlLabel value='travel-train' control={<Radio />} label='Train/Bus' className='radio-button' />
          <FormControlLabel value='travel-drive' control={<Radio />} label='Drive' className='radio-button' />
        </RadioGroup>
      </FormControl>
      <div className='time-container'>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker label="Departure Time" value={travelTime as any} className='time-field'
            onChange={(event) => setTravelTime(event)}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
            }} />
        </LocalizationProvider>
        <TextField margin="dense" label="Duration (hours)" type="number" fullWidth className='duration-field'
          value={duration} onChange={event => setDuration(!event.target.value ? '' : Number(event.target.value))} />
      </div>
    </div>
  );
}
