import './CustomDateCell.scss';
import { DateCellWrapperProps, View } from 'react-big-calendar';
import { Fab, Tooltip, Typography } from '@mui/material';
import { PlanDay } from '../../../../shared/types/plan';
import { DayMenuButton } from './DayMenuButton';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWalking } from '@fortawesome/free-solid-svg-icons';
import { getDayCellLabel } from './calendarUtil';
import { faCommentDots } from '@fortawesome/free-regular-svg-icons';
import CustomTooltip from '../../widget/CustomTooltip';
import { getLocationCity } from 'trip-util';

interface CustomDateCellProps extends DateCellWrapperProps {
  planDay?: PlanDay;
  ordering?: boolean;
  view?: View;
  onAdd?: (date: Date, planDay?: PlanDay) => void;
  onEdit?: (date: Date, planDay?: PlanDay) => void;
  onDelete?: (date: Date) => void;
  onOptimize?: (date: Date, planDay?: PlanDay) => Promise<boolean>;
  onOrder?: (date?: Date) => void;
}

function CustomDateCell(params: CustomDateCellProps) {
  const { value, view, children, planDay, ordering, onAdd, onEdit, onDelete, onOptimize, onOrder, ...props } = params;
  const date: Date = value;
  const range: Date[] = props.range;
  const leftMost = date === range[0];
  const weekend = date.getDay() === 0 || date.getDay() === 6;
  const getIcon = () => {
    if(ordering && onOrder) {
      return (
        <Fab size="small" sx={{ border: 'none' }} className='add-icon' onClick={() => onOrder()}>
          <Tooltip title='Stop Ordering'>
            <CancelIcon />
          </Tooltip>
        </Fab>
      );
    }
    else if(onAdd && !onEdit && !onDelete && !onOptimize && !onOrder) {
      return (
        <Fab size="small" sx={{ border: 'none' }} className='add-icon' onClick={() => onAdd(value, planDay)}>
          <Tooltip title={planDay ? 'Add Event' : 'Add Day'}>
            {planDay ? <AddIcon /> : <AddLocationAltIcon />}
          </Tooltip>
        </Fab>
      );
    }
    else if(onAdd || onEdit || onDelete || onOptimize || onOrder) {
      const tooltip = <Typography fontSize={12}>{getDayCellLabel(planDay?.title, planDay?.dayTrip)}</Typography>;
      return (
        <DayMenuButton date={date} planDay={planDay} tooltip={tooltip} className='add-icon'
          onAdd={onAdd} onEdit={onEdit} onDelete={onDelete} onOptimize={onOptimize} onOrder={onOrder} />
      );
    }
    return null;
  }

  function getTipElement(tooltip: string) {
    return <React.Fragment>
      <Typography fontSize={12} style={{ whiteSpace: 'pre-line' }}>
        {tooltip}
      </Typography>
    </React.Fragment>;
  }

  function getTipIcon(tooltip: string, icon: any, className: string, onClick?: () => void): React.ReactNode {
    return <CustomTooltip title={getTipElement(tooltip)} placement='right' disableInteractive>
      <div className={`${className} ${onClick ? 'clickable' : ''}`} onClick={() => onClick && onClick()}>
        <FontAwesomeIcon icon={icon} />
      </div>
    </CustomTooltip>;
  }

  // get title as links, city1/city2 (city3)
  function getTitleLink(title?: string, locations?: string): any {
    const overnight = !locations || locations != title ? title : null;
    const cities = locations?.split('/') || [];

    return (
      <Tooltip title={getDayCellLabel(title, locations)}>
        <div className='location-title'>
          {cities.map((city, idx) =>
            <a href={`https://www.google.com/search?q=${city} attractions`} target='_blank' key={idx}>
              <div className="tripday-location">{idx > 0 ? '/' : ''}{getLocationCity(city)}</div>
            </a>
          )}
          {overnight ?
            <a href={`https://www.google.com/search?q=${overnight} attractions`} target='_blank'>
              <div className="tripday-location">
                {locations ? '(' : ''}{getLocationCity(overnight)}{locations ? ')' : ''}
              </div>
            </a> : null
          }
        </div >
      </Tooltip>
    );
  }

  return (
    <div className={`custom-date-cell ${leftMost ? 'left-most' : ''} ${weekend ? 'weekend-cell' : ''} ${view === 'week' ? 'week-view' : ''}`}
      {...props}>
      {children}
      {getIcon()}
      {planDay?.direction && !ordering ?
        getTipIcon(planDay.direction, faWalking, 'direction-icon first-icon') : null
      }
      {planDay?.notes && !ordering ?
        getTipIcon(planDay.notes, faCommentDots, `direction-icon ${planDay?.direction ? 'second-icon' : 'first-icon'}`,
          () => onEdit && onEdit(date, planDay)
        ) : null
      }
      <div className='location-label'>{getDayCellLabel(planDay?.title, planDay?.dayTrip)}</div>
      {getTitleLink(planDay?.title, planDay?.dayTrip)}
    </div>
  );
};

export default CustomDateCell;
