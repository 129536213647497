import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import "./ConfirmDialog.scss";

interface ConfirmDialogProps {
  open: boolean;
  label: string;
  buttons: string[];
  onSubmit: (button: string) => void;
};

export function ConfirmDialog(props: ConfirmDialogProps) {
  const { open, label, buttons, onSubmit } = props;

  return (
    <Dialog open={open}>
      <DialogTitle>Confirm</DialogTitle>
      <DialogContent>
        <Divider/>
        <div className='label-text'>{label}</div>
      </DialogContent>
      <DialogActions>
        {
          buttons.map((b, i) => (
            <Button variant='contained' onClick={() => onSubmit(b)}
              color={`${i < buttons.length - 1 ? 'secondary' : 'primary'}`} key={i} >
              {b}
            </Button>
          ))
        }
      </DialogActions>
    </Dialog >
  );
};

export default ConfirmDialog;