import { MapPoint } from "./Mapbox";

export function mergeMapPoints(mapPoints?: MapPoint[]): MapPoint[] {
  const groupedPoints = mapPoints?.reduce((acc, mapPoint, index) => {
    const key = `${mapPoint.lat},${mapPoint.lng}`;

    if(!acc.has(key)) {
      acc.set(key, { lat: mapPoint.lat, lng: mapPoint.lng, num: '', tip: '', location: mapPoint.location, days: 1 });
    }

    const point = acc.get(key);
    point!.num += (point!.num ? ',' : '') + (index + 1);
    point!.tip += (point!.tip ? '<br>' : '') + mapPoint.tip;
    point!.location += (point!.location ? ',' : '') + mapPoint.location;
    point!.days! += 1;

    return acc;
  }, new Map<string, MapPoint>());

  return groupedPoints ? Array.from(groupedPoints.values()) : [];
}