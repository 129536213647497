export function convertDates(obj: any): any {
  // Base case: if obj is not an object or array, return it as is
  if(typeof obj !== 'object' || obj === null || obj instanceof Date || obj instanceof Blob) {
    return obj;
  }

  // If obj is an array, map over it recursively
  if(Array.isArray(obj)) {
    return obj.map(item => convertDates(item));
  }

  function isDateString(value: any): boolean {
    const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z?$/;
    return isoDateRegex.test(value);
  }

  // If obj is an object, iterate over its keys and convert nested values
  const newObj: any = {};
  for(const key in obj) {
    if(obj.hasOwnProperty(key)) {
      const value = obj[key];
      if(typeof value === 'string' && isDateString(value)) {
        newObj[key] = new Date(value); // Convert date string to Date object
      } else if(typeof value === 'object' && value !== null) {
        newObj[key] = convertDates(value); // Recursively convert nested objects
      } else {
        newObj[key] = value; // Leave non-date values unchanged
      }
    }
  }
  return newObj;
}
