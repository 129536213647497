import { useEffect, useState, KeyboardEvent } from 'react';
import {
  TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Select,
  MenuItem, InputLabel, RadioGroup, FormControlLabel, Radio, FormLabel, Autocomplete,
  Alert
} from '@mui/material';
import { EventType, FoodType, Plan, PlanDay, PlanEvent, TimeOfDay } from "../../../../shared/types/plan";
import "./EventDialog.scss";
import dayjs, { Dayjs } from 'dayjs';
import { EventTravelComponent } from './EventTravelComponent';
import { getLocationCity } from 'trip-util';

interface EventDialogProps {
  open?: boolean;
  oevent?: PlanEvent;
  plan?: Plan;
  planDay?: PlanDay;
  editingDay?: boolean;
  onClose?: () => void;
  onSubmit: (value: PlanEvent | PlanDay) => void;
};

type EventUiType = 'activity' | 'travel' | 'hotel' | 'food' | '';
type TimeOfDayOption = Exclude<TimeOfDay, Date> | '';
const options: TimeOfDayOption[] = ['morning', 'afternoon', 'evening', 'all day'];

export function EventDialog(props: EventDialogProps) {
  const { open, oevent, plan, planDay, editingDay, onClose, onSubmit } = props;
  const [location, setLocation] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const [timeOfDay, setTimeOfDay] = useState<TimeOfDayOption>('morning');
  const [eventUiType, setEventUiType] = useState<EventUiType>('activity');
  const [foodType, setFoodType] = useState<FoodType>('restaurant');
  const [travelType, setTravelType] = useState<EventType>('travel-air');
  const [duration, setDuration] = useState<number | string>('activity');
  const [arriving, setArriving] = useState<boolean | undefined>(true);
  const [travelTime, setTravelTime] = useState<Dayjs | null>(null);
  const locations = Array.from(new Set(plan?.days?.map(d => d.title).filter(t => !!t) ?? []));

  useEffect(() => {
    setLocation(oevent?.location || planDay?.title || '');
    setTitle(oevent?.title || '');
    setEventUiType(oevent?.eventType?.startsWith('travel-') ? 'travel' : oevent?.eventType as EventUiType || '');
    setNotes((editingDay ? planDay?.notes : oevent?.notes) ?? '');
    setFoodType(oevent?.foodType || 'restaurant');

    if(oevent) {
      setTimeOfDay(typeof oevent.timeOfDay === 'string' ? oevent.timeOfDay : '');
      setTravelTime(oevent.timeOfDay instanceof Date ? dayjs(oevent.timeOfDay) : null);
      setTravelType(oevent.eventType && oevent.eventType !== 'activity' ? oevent.eventType : 'travel-air');
      setArriving(oevent.arriving);
      setDuration(oevent.duration || '');
    }
  }, [oevent, editingDay, planDay]);

  const handleSubmit = () => {
    if(editingDay) {
      onSubmit({
        title: location.trim(),
        notes: notes.trim(),
        events: []
      });
    }
    else {
      if(!eventUiType) {
        return;
      }

      const event = {
        title: title.trim(),
        notes: notes.trim(),
        timeOfDay: eventUiType === 'travel' ? (travelTime ? travelTime.toDate() : new Date()) : (timeOfDay || 'morning'),
        eventType: eventUiType === 'travel' ? travelType : eventUiType,
        foodType: foodType,
        arriving: arriving,
        duration: duration ? Number(duration) : undefined,
        location: location
      };

      onSubmit(event);
    }

    setTitle('');
    onClose && onClose();
  };

  const handleClose = () => {
    onClose && onClose();
  };

  function keyDown(event: KeyboardEvent) {
    if(event.key === "Enter") {
      handleSubmit();
    }
  }

  function getTimeOfDayLabel(option: TimeOfDayOption): string {
    if(eventUiType !== 'food') {
      return option;
    }

    switch(option) {
      case 'morning':
        return 'Breakfast';
      case 'afternoon':
        return 'Lunch';
      case 'evening':
        return 'Dinner';
      default:
        return 'Any';
    }
  }

  function getTitleLabel(): string {
    switch(eventUiType) {
      case 'activity':
        return "Place of Interest";
      case 'travel':
        return "Flight/Travel Information";
      case 'hotel':
        return "Hotel/Lodging";
      case 'food':
        return "Food Place";
      default:
        return "Event Title";
    }
  }

  return (
    <Dialog open={!!open} onClose={handleClose} className='event-dialog'>
      <DialogTitle>{editingDay ? (!planDay ? 'Add a Day' : 'Edit Location') : 'Create a Event'}</DialogTitle>
      <DialogContent className={`event-dialog-container ${editingDay ? 'day-event' : ''}`}>
        <Autocomplete autoFocus freeSolo options={locations} className='location-field'
          renderInput={(params) => <TextField {...params} label="Location (City of Attraction)" />} fullWidth
          value={location} inputValue={location} onKeyDown={keyDown}
          disabled={!editingDay && !!planDay && oevent?.fromItinerary || planDay?.fromItinerary}
          onInputChange={(event, value) => setLocation(value ?? '')}
          onChange={(event, value) => setLocation(value ?? '')} />
        {!editingDay ?
          <FormControl>
            <FormLabel component="legend">Event Type</FormLabel>
            <RadioGroup value={eventUiType} row={true} className='radio-group'
              onChange={(event) => setEventUiType(event?.target?.value as EventUiType)}>
              <FormControlLabel value='activity' control={<Radio />} label="Attraction" className='radio-button' />
              {!oevent?.fromItinerary ?
                // travel and hotel are only entered by user, not from ai generated itinerary
                <FormControlLabel value='travel' control={<Radio />} label="Travel" className='radio-button' /> : null
              }
              {!oevent?.fromItinerary ?
                <FormControlLabel value='hotel' control={<Radio />} label="Hotel" className='radio-button' /> : null
              }
              <FormControlLabel value='food' control={<Radio />} label="Food" className='radio-button' />
            </RadioGroup>
          </FormControl> : null
        }
        {!editingDay && eventUiType === 'food' ?
          <FormControl>
            <RadioGroup value={foodType} row={true} className='radio-group'
              onChange={(event) => setFoodType(event?.target?.value as FoodType)}>
              <FormControlLabel value='restaurant' control={<Radio />} label="Restaurant" className='radio-button' />
              <FormControlLabel value='bar' control={<Radio />} label="Bar" className='radio-button' />
              <FormControlLabel value='market' control={<Radio />} label="Market" className='radio-button' />
              <FormControlLabel value='street' control={<Radio />} label="Street" className='radio-button' />
            </RadioGroup>
          </FormControl> : null
        }
        {!editingDay && !eventUiType ?
          <Alert severity='warning'>Please select an event type.</Alert> : null
        }
        {!editingDay ?
          <TextField autoFocus margin="dense" label={getTitleLabel()} type="text" fullWidth
            disabled={oevent?.fromItinerary}
            value={title} onKeyDown={keyDown} onChange={event => setTitle(event.target.value)} /> : null
        }
        {eventUiType === 'travel' ?
          <EventTravelComponent locationCity={getLocationCity(location) || ''}
            arriving={arriving} setArriving={(t) => setArriving(t)}
            travelType={travelType} setTravelType={(t) => setTravelType(t)}
            travelTime={travelTime} setTravelTime={(t) => setTravelTime(t)}
            duration={duration} setDuration={(t) => setDuration(t)}
          /> : null
        }
        {(eventUiType === 'food') && !editingDay ?
          <FormControl fullWidth>
            <InputLabel htmlFor="select-label">Time of Day</InputLabel>
            <Select labelId="select-label" id="select" value={timeOfDay} label="Time of Day"
              onChange={event => setTimeOfDay(event.target.value as TimeOfDayOption)}>
              {options.map((option) => (
                <MenuItem key={option} value={option}> {getTimeOfDayLabel(option)} </MenuItem>
              ))}
            </Select>
          </FormControl> : null
        }
        <TextField margin="dense" label="Notes" multiline={true} fullWidth className='notes-field'
          rows={6} value={notes} onChange={event => setNotes(event.target.value)} />
      </DialogContent>
      <DialogActions className='command-container'>
        <Button variant='contained' onClick={handleClose} color='secondary'>Cancel</Button>
        <Button variant='contained' onClick={handleSubmit} disabled={!eventUiType && !editingDay}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventDialog;
