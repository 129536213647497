import axios from "axios";
import _ from 'lodash';
import React, { useContext } from "react";

export class UserManager {
  private user?: UserInfo;
  private inited = false;

  public init() {
    if(!this.inited) {
      this.inited = true;
      this.fetchUser();
    }
  }

  public getUser(): UserInfo | undefined {
    return this.user;
  }

  public isSignedIn(): boolean {
    return !!this.user?.id;
  }

  public logout(): Promise<UserInfo|undefined> {
    return axios.get('/api/logout').then(result => this.user = undefined);
  }

  public fetchUser() {
    axios.get('/api/current-user').then(result => {
      this.user = result.data;
      console.log('user:', this.user);
    }).catch(ex => console.log('Failed to fetch user: ', ex));
  }
}

export const userManager = new UserManager();
export const UserContext = React.createContext<UserManager>(userManager);

export function useUserContext(): UserManager {
  return useContext(UserContext);
}
