import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useUserContext } from '../service/UserContext';

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const location = useLocation();
  const originalUri = location.pathname + (location.search ?? '');
  const [searchParams, setSearchParams] = useSearchParams();
  const userManager = useUserContext();

  if(searchParams.get('login') === 'true' || !userManager.isSignedIn()) {
    userManager.fetchUser();
    searchParams.delete('login');
  }

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await axios.post<{ authenticated: boolean }>('/checkAuth', {
          originalUri
        }).then(result => {
          setIsAuthenticated(result.data.authenticated);
        });
      }
      catch(error) {
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, []);

  if(isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? <>{children}</> : <Navigate to="/login" state={{originalUri}} />
};

export default ProtectedRoute;
