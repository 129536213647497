import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuButton } from '../../widget/MenuButton';
import { faBed, faCalendarPlus, faCar, faCommentDots, faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import "./TripDayMenuButton.scss";

interface TripDayMenuButtonProps {
  onAdd?: () => void;
  onDelete?: () => void;
  onDayTrip?: () => void;
  onOvernight?: () => void;
  onEdit?: () => void;
}

export function TripDayMenuButton(props: TripDayMenuButtonProps) {
  const { onAdd, onDelete, onDayTrip, onOvernight, onEdit } = props;
  const actions = [];

  if(onAdd) {
    actions.push({
      label: 'Add Day',
      icon: <FontAwesomeIcon icon={faCalendarPlus}/>,
      action: () => onAdd && onAdd()
    });
  }

  if(onDelete) {
    actions.push({
      label: 'Remove Day',
      icon: <FontAwesomeIcon icon={faTrashCan}/>,
      action: () => onDelete && onDelete()
    });
  }

  if(onOvernight) {
    actions.push({
      label: 'Change to Overnight',
      icon: <FontAwesomeIcon icon={faBed}/>,
      action: () => onOvernight && onOvernight()
    });
  }

  if(onDayTrip) {
    actions.push({
      label: 'Change to Day Trip',
      icon: <FontAwesomeIcon icon={faCar}/>,
      action: () => onDayTrip && onDayTrip()
    });
  }

  if(onEdit) {
    actions.push({
      label: 'Edit Notes',
      icon: <FontAwesomeIcon icon={faEdit}/>,
      action: () => onEdit && onEdit()
    });
  }

  return MenuButton({ className: 'trip-day-menu-button', actions });
};
