import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Box, Snackbar } from '@mui/material';

const MessageField: React.FC = () => {
  const [message, setMessage] = useState<string>('');
  const [response, setResponse] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e: any): Promise<void> => {
    if(message.trim() === '') {
      setError('Message cannot be empty');
      return;
    }

    try {
      const res = await axios.post<{ message: string }>('/message', { message });
      setResponse(res.data.message); // Assuming the response has a "message" field
      setError(null);
    }
    catch(err) {
      console.log('Failed to post message:', err);
      setError('Failed to send message');
      setResponse(null);
    }
  };

  function clearMessages() {
    axios.post('/clear-messages', {}).then(res => {
      setResponse('Cleared all messages.');
    }).catch(err => {
      console.log('Failed to clear messages:', err);
      setError('Failed to send message');
    });
  }

  return (
    <Box sx={{ maxWidth: 800, margin: '0 auto', textAlign: 'center' }}>
      <TextField
        label="Message"
        variant="outlined"
        multiline
        fullWidth
        rows={4}
        value={message}
        onChange={handleChange}
        error={!!error}
        helperText={error}
      />
      <Box mt={2} sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Broadcast Message
        </Button>
        &nbsp;
        <Button variant="contained" color="secondary" onClick={clearMessages}>
          Clear Messages
        </Button>
      </Box>
      <Snackbar open={!!response} message={response}/>
    </Box>
  );
};

export default MessageField;
