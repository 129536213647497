import { BigCalendarEvent, getGoogleQ, mapEvent, searchEvent, stripAirbnbs } from './calendarUtil';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import MapIcon from '@mui/icons-material/Map';
import "./EventMenuButton.scss";
import { MenuButton } from '../../widget/MenuButton';

interface EventMenuButtonProps {
  className?: string;
  event: BigCalendarEvent;
  location: string;
  onEdit?: (event: BigCalendarEvent) => void;
  onDelete?: (event: BigCalendarEvent) => void;
}

export function EventMenuButton(props: EventMenuButtonProps) {
  const { event, className, location, onEdit, onDelete } = props;
  const actions = [];

  if(onEdit) {
    actions.push({
      label: 'Edit',
      icon: <EditIcon />,
      action: () => onEdit && onEdit(event)
    });
  }

  if(onDelete) {
    actions.push({
      label: 'Remove',
      icon: <DeleteIcon />,
      action: () => onDelete && onDelete(event)
    });
  }

  function search() {
    searchEvent(event, location);
  }

  function mapIt() {
    mapEvent(event, location);
  }

  if(getGoogleQ(event, location)) {
    actions.push({
      label: 'Search',
      icon: <SearchIcon />,
      action: search
    });

    actions.push({
      label: 'Map It',
      icon: <MapIcon />,
      action: mapIt
    });
  }

  return MenuButton({ className, actions });
};
