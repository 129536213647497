import {
  faBath, faBinoculars, faChildren, faChurch, faDice, faHorse, faHouseChimney, faLandmarkFlag, faMountain, faMugSaucer,
  faParachuteBox,
  faPersonHiking, faSailboat,
  faVolleyball,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';
import { faLandmark } from '@fortawesome/free-solid-svg-icons';
import { faBagShopping } from '@fortawesome/free-solid-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faUmbrellaBeach } from '@fortawesome/free-solid-svg-icons';
import { faMasksTheater } from '@fortawesome/free-solid-svg-icons';
import { faMartiniGlassCitrus } from '@fortawesome/free-solid-svg-icons';
import { faPlane } from '@fortawesome/free-solid-svg-icons';
import { faMartiniGlass } from '@fortawesome/free-solid-svg-icons';

export function getAttractionIcon(activityType: string): IconDefinition {
  let icon = faLocationDot;

  switch((activityType.split('|')[0] || '').toLowerCase()) {
    case 'hiking':
    case 'outdoor':
      icon = faPersonHiking;
      break;
    case 'historic':
    case 'historic city':
    case 'landmark':
    case 'cultural':
    case 'palace':
    case 'palaces':
    case 'ruin':
    case 'ancient civilization':
      icon = faLandmark;
      break;
    case 'museum':
    case 'art museum':
    case 'art museums':
    case 'art':
      icon = faLandmarkFlag;
      break;
    case 'food':
      icon = faUtensils;
      break;
    case 'park':
      icon = faMountain;
      break;
    case 'shopping':
      icon = faBagShopping;
      break;
    case 'beach':
      icon = faUmbrellaBeach;
      break;
    case 'entertainment':
      icon = faMasksTheater;
      break;
    case 'nightlife':
      icon = faMartiniGlassCitrus;
      break;
    case 'brewery':
      icon = faMartiniGlass;
      break;
    case 'church':
    case 'churches':
      icon = faChurch;
      break;
    case 'coastal':
      icon = faSailboat;
      break;
    case 'natural':
    case 'natural wonder':
    case 'nature':
    case 'scenic':
      icon = faBinoculars;
      break;
    case 'relaxation':
      icon = faMugSaucer;
      break;
    case 'old town':
      icon = faHouseChimney;
      break;
    case 'casino':
      icon = faDice;
      break;
    case 'food':
      icon = faUtensils;
      break;
    case 'wildlife':
      icon = faHorse;
      break;
    case 'spa':
      icon = faBath;
      break;
    case 'sports':
      icon = faVolleyball;
      break;
    case 'family-friendly':
      icon = faChildren;
      break;
    case 'theme park':
      icon = faParachuteBox;
      break;
    case 'n/a':
      icon = faPlane;
      break;
    default:
      console.log("Unknown activity type: " + activityType);
  }

  return icon;
}
