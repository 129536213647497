import { TripInfo, UserTripInfos } from "@shared/types/tripInfo";
import { TripSummary } from "@shared/types/summary";
import axios from "axios";
import _ from 'lodash';
import React, { useContext } from "react";

export class TripCache {
  private trips?: TripInfo[];
  private archivedTrips?: TripInfo[];
  private featuredTrips?: TripSummary[];
  private unsavedTrip?: TripInfo | null;
  private userTripInfosPromise?: Promise<UserTripInfos>;
  private updateTime = new Date();
  private pendingRefresh?: Promise<any>;

  public async getTrips(): Promise<TripInfo[]> {
    if(this.pendingRefresh) {
      await this.pendingRefresh;
    }

    if(this.userTripInfosPromise) {
      await this.userTripInfosPromise;
    }

    if(this.trips) {
      return Promise.resolve(this.trips);
    }

    return this.refreshTrips();
  }

  public getUpdateTime(): Date {
    return this.updateTime;
  }

  public setPendingChange(result?: Promise<any>) {
    if(result) {
      this.updateTime = new Date();
    }

    this.pendingRefresh = result;
  }

  public async refreshTrips(): Promise<TripInfo[]> {
    this.updateTime = new Date();
    this.userTripInfosPromise = axios.get<UserTripInfos>('/trips').then(r => r.data);
    return this.userTripInfosPromise?.then(info => {
      this.updateTime = new Date();
      this.trips = info?.tripInfos || [];
      this.unsavedTrip = info?.unsavedTrip || null;
      this.userTripInfosPromise = undefined;
      return this.trips;
    });
  }

  public setTrips(trips: TripInfo[]): TripInfo[] {
    this.trips = _.sortBy(trips, ['modified']).reverse();
    return this.trips;
  }

  public getArchivedTrips(): Promise<TripInfo[]> {
    if(this.archivedTrips) {
      return Promise.resolve(this.archivedTrips);
    }

    return axios.get<UserTripInfos>('/archived-trips')
      .then(r => this.archivedTrips = r.data.tripInfos || []);
  }

  public setArchivedTrips(trips: TripInfo[]): TripInfo[] {
    this.archivedTrips = _.sortBy(trips, ['modified']).reverse();
    return this.archivedTrips;
  }

  public getUnsavedTrip(): Promise<TripInfo | null> {
    if(this.unsavedTrip !== undefined) {
      return Promise.resolve(this.unsavedTrip);
    }

    return this.getTrips().then(r => this.unsavedTrip || null);
  }

  public setUnsavedTrip(trip: TripInfo | null) {
    this.unsavedTrip = trip;
  }

  private handleResult(ack: boolean, otrips?: TripInfo[], archived?: boolean) {
    if(!ack) {
      if(archived) {
        this.archivedTrips = otrips;
      }
      else {
        this.trips = otrips;
      }
    }

    return ack;
  }

  public deleteTrip(tripId: string, archived: boolean, setter: (trips: TripInfo[]) => void): Promise<boolean> {
    const trips = archived ? this.archivedTrips : this.trips;
    const ntrips = trips?.filter(t => t._id !== tripId);

    setter(ntrips || []);

    return axios.delete(archived ? `/archived-trip/${tripId}` : `/trip/${tripId}`)
      .then(ack => this.handleResult(!!ack, trips, archived))
      .catch(ex => this.handleResult(false, trips, archived));
  }

  public flipArchive(tripId: string, archived: boolean,
    setTripsCallback: (trips: TripInfo[]) => void,
    setArchivedTripsCallback: (trips: TripInfo[]) => void): Promise<boolean> {
    const otrips = archived ? this.archivedTrips : this.trips;

    if(archived) {
      setTripsCallback(this.setTrips(this.trips!.concat(otrips!.filter(t => t._id === tripId))));
      setArchivedTripsCallback(this.setArchivedTrips(otrips!.filter(t => t._id !== tripId)));
    }
    else {
      setArchivedTripsCallback(this.setArchivedTrips(this.archivedTrips!.concat(otrips!.filter(t => t._id === tripId))));
      setTripsCallback(this.setTrips(otrips!.filter(t => t._id !== tripId)));
    }

    return axios.post(`/${archived ? 'unarchive-trip' : 'archive-trip'}/${tripId}`)
      .then(ack => this.handleResult(!!ack, otrips, archived))
      .catch(ex => this.handleResult(false, otrips, archived));
  };

  public async getFeaturedTrips(setter: (trips: TripSummary[]) => void): Promise<TripSummary[]> {
    const promise = axios.get<TripSummary[]>('/get-featured-trips')
      .then(r => {
        this.featuredTrips = r.data || [];
        setter(this.featuredTrips);
        return this.featuredTrips;
      }).catch(ex => {
        console.log('Failed to fetch featured trips:', ex);
        setter([]);
        return [];
      });

    return this.featuredTrips ? Promise.resolve(this.featuredTrips) : promise;
  }

  public async setFeatured(_id: string, featured: boolean): Promise<any> {
    return axios.post(`/featured-trip/${_id}`, { featured }).then(a => this.refreshTrips());
  }
}

export const tripCache = new TripCache();
export const TripCacheContext = React.createContext<TripCache>(tripCache);

export function useTripCache() {
  return useContext(TripCacheContext);
}
