import { Popover } from "@mui/material";
import { useState, MouseEvent, ReactNode } from "react";

type DivWithPopoverProps = {
  children: ReactNode;
  anchor: any;
}

export function DivWithPopover(props: DivWithPopoverProps) {
  const { children, anchor } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);

  const handleMouseOver = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseOut = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(null);
  };

  return <div onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut} style={{ cursor: 'default' }}>
    {anchor}
    <Popover open={open} anchorEl={anchorEl} disableRestoreFocus onClose={handleMouseOut}
      sx={{ pointerEvents: 'none' }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}>
      <div onMouseLeave={handleMouseOut}>
        {children}
      </div>
    </Popover>
  </div>;
}
