import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Profile: React.FC = () => {
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    axios.get('/api/current_user').then(response => {
      setUser(response.data);
    });
  }, []);

  const handleLogout = () => {
    window.location.href = '/api/logout';
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Profile</h1>
      <p>Name: {user.displayName}</p>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default Profile;
