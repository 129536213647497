import React from 'react';
import { Card, CardContent, CardHeader, Typography, List, ListItem, Button } from '@mui/material';
import "./Login.scss";
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
  const handleGoogle = () => { window.location.href = '/auth/google'; };
  const handleFacebook = () => { window.location.href = '/auth/facebook'; };
  const navigate = useNavigate();
  const cancel = () => navigate('/');

  return (
    <div className='login-container'>
      <Card className='inner-container'>
        <CardHeader title={<Typography variant="h6">Login</Typography>} />
        <CardContent>
          <div className='login-label'>
            Sign in to start your advanture.
          </div>
          <List>
            <ListItem className='item-button'>
              <Button variant='contained' color="primary" onClick={handleGoogle}>
                Sign in with Google
              </Button>
            </ListItem>
            <ListItem className='item-button'>
              <Button variant='contained' color="primary" onClick={handleFacebook}>
                Sign in with Facebook
              </Button>
            </ListItem>
          </List>
          <div className='action-pane'>
            <Button onClick={cancel} variant='outlined'>Cancel</Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Login;
