import { TimeOfDay } from "@shared/types/plan";
import { BigCalendarEvent } from "./calendarUtil";
import { spreadEvents } from "./spreadEvents";

// Helper function to calculate timeOfDay
function getTimeOfDay(date: Date): TimeOfDay {
  const hours = date.getHours();
  if(hours < 12) {
    return 'morning';
  } else if(hours < 18) {
    return 'afternoon';
  } else {
    return 'evening';
  }
}

export function updateBigCalendarEventStart(
  events: BigCalendarEvent[],
  targetEvent: BigCalendarEvent,
  newStart: Date
): BigCalendarEvent[] {
  // Find the index of the target event
  const index = events.findIndex(
    (event) =>
      event.start?.getTime() === targetEvent.start?.getTime() &&
      event.end?.getTime() === targetEvent.end?.getTime()
  );

  if(index === -1) {
    throw new Error("Event not found in the array.");
  }

  // Remove the target event from the array
  const [updatedEvent] = events.splice(index, 1);

  // Update the start time and timeOfDay
  if(updatedEvent.start) {
    updatedEvent.start = newStart;
    updatedEvent.timeOfDay = getTimeOfDay(newStart);
  }

  // Find the correct position to insert the updated event
  let insertIndex = 0;
  for(let i = 0; i < events.length; i++) {
    if(events[i].start && newStart < events[i].start!) {
      insertIndex = i;
      break;
    } else {
      insertIndex = events.length; // Insert at the end if no earlier spot is found
    }
  }

  // Insert the updated event at the correct position
  events.splice(insertIndex, 0, updatedEvent);

  return spreadEvents(events as any[]);
}