import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Backdrop, CircularProgress, Snackbar } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import BallotIcon from "@mui/icons-material/Ballot";
import StarIcon from "@mui/icons-material/Star";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { TripInfo } from "../../../../shared/types/tripInfo";
import { faBoxArchive, faStar, faUmbrellaBeach } from "@fortawesome/free-solid-svg-icons";
import "./MyTripItem.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { format } from 'date-fns';
import { MenuButton } from "../../widget/MenuButton";
import { useUserContext } from "../../service/UserContext";
import ConfirmDialog from "../../widget/ConfirmDialog";
import React from "react";
import { pushReturnUrl } from "../../util/ui-util";

interface TripItemProps {
  trip: TripInfo;
  archived?: boolean;
  onDelete: (_id: string) => void;
  onArchive: (_id: string, changeMode?: boolean) => Promise<any>;
  onFeatured: (_id: string, featured: boolean) => void;
}

function TripItem0(props: TripItemProps) {
  const { trip, archived, onDelete, onArchive, onFeatured } = props;
  const [message, setMessage] = useState<string>("");
  const [featured, setFeatured] = useState<boolean>(false);
  const [pendingEdit, setPendingEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const linkState = pushReturnUrl(location);
  const admin = useUserContext().getUser()?.admin;
  const actions = [
    {
      label: 'Explore & Plan',
      icon: <AutoAwesomeIcon />,
      action: () => archived ? unarchiveAndEdit() : navigate(`/open/${trip._id}`, { state: linkState })
    },
    {
      label: 'Show Outline',
      icon: <BallotIcon />,
      action: () => navigate(`/outline/${trip._id}`, { state: linkState })
    },
    {
      label: 'Delete',
      icon: <DeleteIcon />,
      action: () => onDelete(trip._id)
    },
    {
      label: archived ? 'Unarchive' : 'Archive',
      icon: archived ? <UnarchiveIcon /> : <ArchiveIcon />,
      action: () => onArchive(trip._id)
    }
  ];

  function unarchiveAndEdit(action?: string) {
    if(!action) {
      setPendingEdit(true);
    }
    else {
      if(action === 'OK') {
        setLoading(true);
        onArchive(trip._id, true).then(a => {
          navigate(`/open/${trip._id}`, { state: linkState });
          setLoading(false);
        }).catch(ex => {
          setLoading(false);
        });
      }

      setPendingEdit(false);
    }
  }

  useEffect(() => {
    setFeatured(!!trip?.featured);
  }, [trip]);

  if(admin && !archived) {
    actions.push({
      label: featured ? 'Remove from Featured' : 'Set as Featured',
      icon: <StarIcon />,
      action: () => onFeatured(trip._id, !featured)
    })
  }

  return (
    <div className="trip-item-container">
      <Avatar>
        <FontAwesomeIcon icon={archived ? faBoxArchive : (featured ? faStar : faUmbrellaBeach)} />
      </Avatar>
      {archived ?
        <div className="trip-meta" onClick={(event) => unarchiveAndEdit()}>
          <TripItemContent trip={trip} />
        </div> :
        <Link to={`/open/${trip._id}`} state={linkState} className="trip-meta">
          <TripItemContent trip={trip} />
        </Link>
      }
      <MenuButton actions={actions} />
      <Snackbar open={!!message} message={message} autoHideDuration={10000}
        onClose={() => setMessage("")}></Snackbar>
      <ConfirmDialog open={pendingEdit} label="Unarchive and Edit?" buttons={['Cancel', 'OK']}
        onSubmit={unarchiveAndEdit} />
      <Backdrop open={loading}><CircularProgress /></Backdrop>
    </div>
  );
}

interface TripItemContentProps {
  trip: TripInfo;
}

function TripItemContent({ trip }: TripItemContentProps) {
  return (
    <>
      <div className="titleDiv">
        <div className="trip-title">{trip.tripName}</div>
        <div>{format(trip.modified, 'yyyy-MM-dd HH:mm')}</div>
      </div>
      <div>{trip.summary}</div>
    </>
  );
}

export const TripItem = React.memo(TripItem0);